<template>
    <div>
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row g-2">
                    <div class="col">
                        <DbEntitiesList v-if="table" :table="table" :key="table._id" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../services/api";
import DbEntitiesList from "../components/DbEntitiesList";
import { store } from "../store";

export default {
    name: "DbEntitiesPage",
    components: {
        DbEntitiesList
    },
    data() {
        return {
            table: null
        };
    },
    async mounted() {
        store.showSidebar = true;
        store.showHeader = true;
        store.showBackButton = true;
        store.backUrl = "/database/tables";

        var response = await Api.database.tables.get(this.$route.params.table_id);
        this.table = response.data;
    }
}
</script>