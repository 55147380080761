<template>
    <div>
        <div
            class="modal modal-blur fade"
            id="modal-variable-form"
            tabindex="-1"
            style="display: none"
            aria-modal="true"
            role="dialog">
            <div
                class="modal-dialog modal-lg modal-dialog-centered"
                role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{actionTitle}}</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label class="form-label">Nome</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="variable.name"
                                placeholder="Nome da sua variável"
                            />
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3">
                                    <label class="form-label">Código da variável (key)</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="variable.key"
                                        placeholder="Código da sua variável"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div>
                                    <label class="form-label">Conteúdo da variável</label>
                                    <textarea class="form-control" rows="3" v-model="variable.value"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-link link-secondary" data-bs-dismiss="modal" @click="cancel()">
                            Cancelar
                        </button>
                        <button class="btn btn-primary ms-auto" data-bs-dismiss="modal" @click="save()">
                            <i class="ti ti-plus"></i>
                            Salvar variável
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VariableForm',
    props: {
        action: {
            type: String,
            required: true,
            default: 'create'
        },
        data: {
            type: Object,
            required: false,
            default: null
        }
    },
    data(){
        return {
            actionTitle: 'Criar variável',
            variable: {}
        }
    },
    mounted(){
        if(this.action === 'edit'){
            this.actionTitle = 'Editar variável'
        }

        this.$watch('data', (newData) => {
            this.variable = newData
        })
    },
    methods: {
        cancel(){
            this.$emit('cancel')
        },
        save(){
            this.$emit('save', this.variable)
        }
    }
}
</script>