<template>
    <div class="page-wrapper">

        <div class="row mb-3">
            <div class="col">
                <div class="card">

                    <div class="card-body">
                        <div class="row mb-1">
                            <div class="col">
                                <h3>Execuções finalizadas na última hora</h3>
                            </div>
                            <div class="col-auto">
                                <button class="btn" @click="updateChart()">
                                    <i class="ti ti-refresh"></i> Atualizar
                                </button>
                            </div>
                        </div>
                        <apexchart height="160" width="100%" type="bar" :options="chartOptions" :series="chartSeries"></apexchart>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="row m-3 mb-1">
                        <div class="col">
                            <h3 class="card-title">Flows executados</h3>
                        </div>
                        <div class="col-auto">
                                <button class="btn" @click="updateLogs()">
                                    <i class="ti ti-refresh"></i> Atualizar
                                </button>
                            </div>
                    </div>

                    <div class="card" v-show="!statusList.length">
                        <div class="card-body">
                            <p class="text-muted">
                                <span class="execution-spinner"></span>
                                Aguardando execuções...
                            </p>
                        </div>
                    </div>

                    <div v-show="statusList.length">
                        <table class="table table-vcenter card-table">
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Flow</th>
                                    <th>ID da execução</th>
                                    <th>Inspecionar</th>
                                    <th>Iniciado em</th>
                                    <th>Tempo de execução</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="status in statusList" :key="status.execution_id">
                                    <td>
                                        <div class="badge">Finalizado</div>
                                    </td>
                                    <td>
                                        <a :href="'/flows/' + status.flow_id" target="_blank">{{ flowName(status.flow_id)
                                        }}</a>
                                    </td>
                                    <td>
                                        {{ status.execution_id }} <br>
                                    </td>
                                    <td>
                                        <button class="btn btn-sm btn-outline-primary" @click="goToLogs(status)">
                                            <i class="ti ti-search"></i>
                                            Ver logs
                                        </button>
                                    </td>
                                    <td>
                                        <small class="text-muted">{{ formatDatetime(status.start_time) }}</small>
                                    </td>
                                    <td>
                                        <span class="badge bg-blue-lt" v-if="status.execution_time < 1000">{{
                                            status.execution_time }}ms</span>
                                        <span class="badge bg-blue-lt" v-if="status.execution_time > 1000">{{
                                            getElapsedTimeFromMiliseconds(status.execution_time) }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        <Pagination :key="paginationKey" :showPerPage="showPerPage" :currentPage="currentPage"
                            :total="total" v-if="total" @pageChange="updatePage" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
#executions-chart {
    height: 160px;
}

.execution-spinner {
    width: 16px;
    height: 16px;
    border: 3px solid #ddd;
    border-bottom-color: #333;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>

<script>
import moment from 'moment-timezone';
import { store } from '../store';
import EventBus from '../services/event-bus'
import EngineApi from "../services/engine-api";
import Api from "../services/api";
import Pagination from '../components/Pagination.vue'

export default {
    name: 'MonitoringCompletedFlowsPage',
    components: {
        Pagination
    },
    data() {
        return {
            socket: null,
            statusList: [],
            interval: null,
            nodesLibrary: [],
            flows: [],
            paginationKey: null,
            currentPage: 1,
            showPerPage: 10,
            total: 0,
            chartOptions: {
                chart: {
                    title: "Execuções da última hora",
                    toolbar: {
                        show: false
                    },
                    animations: {
                        enabled: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                xaxis: {
                    type: 'datetime'
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: false,
                            total: {
                                enabled: false
                            }
                        }
                    }
                }
            },
            chartSeries: [
                {
                    name: 'Execuções',
                    data: []
                }
            ]
        }
    },
    async mounted() {
        moment.locale('pt-br')

        EventBus.emit('set-header-title', 'Flows executados')

        var nodesLibraryResponse = await EngineApi.getNodesLibrary();
        this.nodesLibrary = nodesLibraryResponse.data;

        var flowsResponse = await Api.flows.list(1, 1000);
        this.flows = flowsResponse.data.items;

        this.loadLogs();
        this.updateChart();
    },
    methods: {
        async loadLogs() {
            this.paginationKey = Math.random();

            var response = await Api.monitoring.getExecutionsCompleted(this.currentPage, this.showPerPage);

            this.statusList = []
            response.data.items.forEach(item => {
                this.statusList.push({
                    execution_id: item.data.execution_id,
                    flow_id: item.data.flow_id,
                    start_time: moment(item.data.timestamp).subtract(item.data.message.execution_time, 'miliseconds').format('YYYY-MM-DD HH:mm:ss'),
                    end_time: item.data.timestamp,
                    execution_time: item.data.message.execution_time
                })
            })

            this.total = response.data.total;
        },
        getElapsedTime(start_time) {
            var start = moment(start_time);
            var end = moment();
            var duration = moment.duration(end.diff(start))

            var hours = parseInt(duration.asHours())
            var minutes = parseInt(duration.asMinutes()) - hours * 60
            var seconds = parseInt(duration.asSeconds()) - hours * 60 * 60 - minutes * 60

            return `${hours}h ${minutes}m ${seconds}s`
        },
        getElapsedTimeFromMiliseconds(miliseconds) {
            var duration = moment.duration(miliseconds);

            var hours = parseInt(duration.asHours())
            var minutes = parseInt(duration.asMinutes()) - hours * 60
            var seconds = parseInt(duration.asSeconds()) - hours * 60 * 60 - minutes * 60

            return `${hours}h ${minutes}m ${seconds}s`
        },
        getFromNow(string) {
            return moment(string).fromNow()
        },
        formatDatetime(string) {
            return moment(string).format('DD/MM/YYYY HH:mm:ss')
        },
        getNodeByType(type) {
            return this.nodesLibrary.nodes.find(node => node.name == type)
        },
        getNodeName(type) {
            var node = this.getNodeByType(type)
            return node ? node.label : type
        },
        goToLogs(status) {
            this.$router.push({
                path: '/monitoring',
                query: {
                    filters: JSON.stringify({
                        date_alias: '1000d',
                        execution_id: status.execution_id
                    })
                }
            })
        },
        flowName(flow_id) {
            var flow = this.flows.filter(w => w._id == flow_id)
            return flow[0] ? flow[0].name : ''
        },
        updatePage(page) {
            this.currentPage = parseInt(page)
            this.loadLogs()
        },
        async updateChart() {
            var response = await Api.monitoring.getExecutionsCompletedTimeseries()
            this.chartSeries[0].data = response.data.map(item => {
                return {
                    x: moment(item.x).format('YYYY-MM-DD HH:mm:ss'),
                    y: parseInt(item.y)
                }
            })
        },
        async updateLogs() {
            this.currentPage = 1
            this.loadLogs()
        }
    }
}
</script>