<template>
    <div>
        <div
            class="modal modal-blur fade"
            id="modal-flow-manual-trigger-form"
            tabindex="-1"
            style="display: none"
            aria-modal="true"
            role="dialog">
            <div
                class="modal-dialog"
                role="document">
                <div class="modal-content">
                    <form action="" method="post" @submit="submitForm">
                        <div class="modal-header">
                            <h5 class="modal-title">Executar Flow</h5>
                            <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                            </button>
                        </div>
                        <div class="modal-body" v-show="successMessage">
                            <h4>
                                <span class="text-green">
                                    <i class="ti ti-circle-check"></i>
                                </span>
                                {{successMessage}}
                            </h4>
                        </div>
                        <div class="modal-body" v-show="!successMessage">
                            <p>{{description}}</p>
                            <p class="alert alert-danger" v-show="errorMessage">{{errorMessage}}</p>

                            <div class="row mb-3" v-for="field in fields" :key="field.name">
                                <div class="col">
                                    <label class="form-label" :class="parseInt(field.required) ? 'required' : ''">{{field.label}}</label>
                                    <input type="text" v-bind:name="field.name" class="form-control" v-model="form_data[field.name]" v-if="field.type == 'text'" />
                                    <textarea class="form-control" v-bind:name="field.name" v-model="form_data[field.name]" v-if="field.type == 'textarea'" />
                                    <input type="file" v-bind:name="field.name" class="form-control" @change="updateFiles" v-if="field.type == 'upload'" />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer" v-show="!successMessage">
                            <button
                                type="button"
                                class="btn btn-link link-secondary"
                                data-bs-dismiss="modal">
                                Cancelar
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary ms-auto"
                                @click="submitForm">
                                Executar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EngineApi from '../services/engine-api'

export default {
    name: 'FlowManualTriggerForm',
    props: {
        flow: {
            type: Object,
            required: true
        },
        fields: {
            type: Array,
            required: true
        },
        description: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            modal: null,
            form_data: {},
            files: {},
            errorMessage: null,
            successMessage: null
        }
    },
    mounted() {
        for(let field of this.fields) {
            if (field.type === 'upload') continue;
            this.form_data[field.name] = ''
        }

        this.modal = new bootstrap.Modal(document.getElementById('modal-flow-manual-trigger-form'), {
            keyboard: false
        })

        this.modal.show()

        setTimeout(() => {
            document.querySelector('form [name=' + this.fields[0].name + ']').focus()
        }, 500);
    },
    methods: {
        updateFiles(e) {
            this.files = {}

            for (let field of this.fields) {
                if (field.type == 'upload') {
                    this.files[field.name] = e.target.files
                }
            }
        },
        async submitForm(e){
            e.preventDefault()
            this.errorMessage = null
            let formData = this.prepareFormData()

            try {
                await EngineApi.flows.executeWithForm(this.flow._id, formData)
                this.successMessage = 'Flow iniciado! Verifique os logs de monitoramento para detalhes da execução.'

                setTimeout(() => {
                    this.modal.hide()
                }, 1000);

            } catch (error) {
                if (error.response.data) {
                    this.errorMessage = error.response.data.message
                } else {
                    this.errorMessage = error.message
                }
            }
        },
        prepareFormData() {
            let data = new FormData()

            for (let field of this.fields) {
                if (field.type == 'upload' && this.files[field.name]) {
                    for (let file of this.files[field.name]) {
                        data.append(field.name, file)
                    }
                } else {
                    data.append(field.name, this.form_data[field.name])
                }
            }

            return data
        },
    }
}
</script>