<template>
    <div>
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row g-2">
                    <div class="col">
                        <div class="card">
                            <div class="card-header">
                                <div class="col">
                                    <h3 class="card-title">
                                        <i class="ti ti-list-check"></i>
                                        Editar fila interna
                                    </h3>
                                </div>
                            </div>
                            
                            <div class="card-body">
                                <div class="row mb-3">
                                    <div class="col">
                                        <label class="form-label required">Nome da fila</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Exemplo: Sincronização de pedidos"
                                                v-model="queue.name"
                                            />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">Limite de execuções por segundo</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="1"
                                                v-model="queue.max_per_second"
                                            />
                                            <span class="form-hint">Número máximo de consumos por segundo</span>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body" v-if="validationErrors.length">
                                <div class="row">
                                    <div class="col">
                                        <h5>Erros encontrados:</h5>
                                        <ul>
                                            <li v-for="error in validationErrors" :key="error" class="text-red">
                                                {{error}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-auto align-right">
                                        <button class="btn btn-primary" @click="saveQueue">
                                            <i class="ti ti-device-floppy"></i>
                                            Salvar fila
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "../store";
import Api from "../services/api";
import EventBus from "../services/event-bus";

export default {
    name: "QueueEditPage",
    data() {
        return {
            queue: {
                name: '',
                max_per_second: 1
            },
            validationErrors: []
        }
    },
    async mounted() {
        store.showSidebar = true;
        store.showHeader = true;
        store.showBackButton = true;
        store.backUrl = '/queues';

        // new
        if (!this.$route.params.id) {
            this.queue = {
                name: '',
                max_per_second: 1
            }
            return;
        }

        var response = await Api.queues.get(this.$route.params.id);
        this.queue = response.data;
    },
    methods: {
        async saveQueue() {
            var errors = this.validate();
            this.validationErrors = errors;

            if (errors.length) {
                return;
            }
            
            try {
                // new
                if (!this.$route.params.id) {
                    var response = await Api.queues.create(this.queue);
                    this.queue = response.data;
                } else {
                    // update
                    this.queue.apply_default_values_new_fields = this.applyDefaultValuesOnNewFields;
                    await Api.queues.update(this.queue._id, this.queue);
                }

                EventBus.emit('message', {
                    type: 'success',
                    message: 'A file interna foi salva com sucesso!'
                })

                this.$router.push('/queues/view/' + this.queue._id);
            } catch (e) {
                var message = e.message;
                if (e.response && e.response.data && e.response.data.message) {
                    message = e.response.data.message;
                }

                if (e.response && e.response.data && e.response.data.error) {
                    message = e.response.data.error;
                }

                if (message.indexOf("name_1 dup key") != -1) {
                    message = "Já existe uma file interna com este nome.";
                }

                if (message.indexOf("identifier_1 dup key") != -1) {
                    message = "Já existe uma file interna com este identificador.";
                }

                EventBus.emit('message', {
                    type: 'danger',
                    message: 'Ocorreu um erro ao salvar a file interna: ' + message
                })
            }
        },

        validate() {
            var errors = [];

            if (!this.queue.name) {
                errors.push(`O campo "Nome da fila" é obrigatório.`);
            }

            return errors;
        },
    }
}
</script>