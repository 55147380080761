<template>
    <div>
        <div>
            <div class="page-wrapper">
                <div class="page-body">
                    <div class="row g-2">
                        <div class="col">
                            <div class="card">
                                <div class="card-header">
                                    <div class="col">
                                        <h3 class="card-title">
                                            <i class="ti ti-cpu"></i>
                                            Conectores personalizados
                                        </h3>
                                    </div>
                                    <div class="col-auto">
                                        <a href="/custom-nodes/new" class="btn btn-primary">
                                            <i class="ti ti-plus"></i>
                                            Criar conector
                                        </a>
                                    </div>
                                </div>

                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-3 mb-2">
                                            <input type="text" class="form-control" v-model="filter.query"
                                                @change="updateFilter" placeholder="Buscar conectores personalizados">
                                        </div>
                                    </div>
                                </div>

                                <div class="table" v-if="customNodesList.length">
                                    <table class="table table-vcenter card-table">
                                        <thead>
                                            <tr>
                                                <th>Imagem</th>
                                                <th>Nome / Serviço</th>
                                                <th>Tipo de execução</th>
                                                <th>Status</th>
                                                <!-- <th>Versão</th> -->
                                                <th>Atualização</th>
                                                <th>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="customNode in customNodesList" v-bind:key="customNode._id">
                                                <td width="130">
                                                    <a v-bind:href="'/custom-nodes/' + customNode._id">
                                                        <div class="avatar avatar-lg rounded" v-if="customNode.imageData">
                                                            <img v-bind:src="customNode.imageData" />
                                                        </div>
                                                        <span class="avatar avatar-lg rounded" v-if="!customNode.imageData"></span>
                                                    </a>
                                                </td>
                                                <td>
                                                    <span class="text-muted">{{ customNode.service_name }}</span>
                                                    <h3 class="m-0">
                                                        <a v-bind:href="'/custom-nodes/' + customNode._id" class="name-link">
                                                            {{ customNode.name }}
                                                        </a>
                                                    </h3>
                                                    <p>{{ customNode.description }}</p>
                                                </td>
                                                <td>
                                                    {{ getExecutionTypeLabel(customNode.execution_type) }}
                                                </td>
                                                <td>
                                                    <span v-if="customNode.enabled" class="badge bg-green text-green-fg">Ativo</span>
                                                    <span v-else class="badge bg-red text-red-fg">Inativo</span>
                                                </td>
                                                <!-- <td>
                                                    {{ customNode.version }}
                                                </td> -->
                                                <td>
                                                    {{ formatDate(customNode.updated_at) }}
                                                </td>
                                                <td>
                                                    <span class="dropdown">
                                                        <button class="btn btn-sm dropdown-toggle"
                                                            data-bs-toggle="dropdown">
                                                            Opções
                                                        </button>
                                                        <div class="dropdown-menu">
                                                            <a v-bind:href="'/custom-nodes/' + customNode._id" class="dropdown-item">
                                                                <i class="ti ti-pencil"></i>
                                                                Editar
                                                            </a>
                                                            <a href="#" @click="(e) => { duplicateCustomNode(e, customNode) }"
                                                                class="dropdown-item">
                                                                <i class="ti ti-copy"></i>
                                                                Duplicar conector
                                                            </a>
                                                            <a v-bind:href="'/custom-nodes/delete/' + customNode._id"
                                                                class="dropdown-item bg-danger-lt">
                                                                <i class="ti ti-trash"></i>
                                                                Remover
                                                            </a>
                                                        </div>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="card-body" v-if="!customNodesList.length && !filter.query">
                                    <div class="empty">
                                        <p class="empty-title">
                                            Nenhum conector criado ainda.
                                        </p>
                                        <p class="empty-subtitle text-muted">
                                            Clique no botão abaixo para criar seu primeiro conector personalizado!
                                        </p>
                                        <div class="empty-action">
                                            <a href="/custom-nodes/new" class="btn btn-primary">
                                                <i class="ti ti-plus"></i>
                                                Criar conector
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-body" v-if="!customNodesList.length && filter.query">
                                    <div class="empty bg-muted-lt">
                                        <p class="empty-title">
                                            Nenhum conector encontrado para esta busca.
                                        </p>
                                    </div>
                                </div>

                                <div class="card-footer">
                                    <Pagination :key="paginationKey" :showPerPage="showPerPage" :currentPage="currentPage"
                                        :total="total" v-if="total" @pageChange="updatePage" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "../services/event-bus";
import Api from "../services/api";
// import EngineApi from "../services/engine-api";
import { store } from "../store";
import Pagination from "../components/Pagination";
import moment from "moment";

export default {
    name: "CustomNodesPage",
    components: {
        Pagination
    },
    data() {
        return {
            paginationKey: null,
            showPerPage: 10,
            currentPage: 1,
            total: 0,
            customNodesList: [],
            filter: {
                query: ""
            }
        }
    },
    async mounted() {
        store.showSidebar = true;
        store.showHeader = true;
    },
    async mounted() {
        this.currentPage = parseInt(this.$route.query.page) || 1;
        this.filter.query = this.$route.query.query || "";
        this.loadCustomNodes();
    },
    methods: {
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss");
        },
        async loadCustomNodes(){
            this.paginationKey = Math.random();

            var response = await Api.customNodes.list(this.currentPage, this.showPerPage, this.filter);

            this.customNodesList = response.data.items;
            this.total = response.data.total;
        },
        updateFilter() {
            this.$router.replace({
                query: {
                    page: 1,
                    query: this.filter.query
                }
            });
            
            this.loadCustomNodes();
        },

        async duplicateCustomNode(e, customNode) {
            e.preventDefault();

            if (confirm("Deseja realmente duplicar este conector? A cópia será criada desabilitada.")) {
                await Api.customNodes.duplicate(customNode._id)

                EventBus.emit('message', {
                    type: 'success',
                    message: 'Conector duplicado com sucesso!'
                })
                
                this.$router.go()
            }
        },
        
        updatePage(page) {
            this.currentPage = page;
            this.paginationKey = Math.random();

            var queryParams = {
                page: page
            }

            if (this.filter.query) queryParams.query = this.filter.query;

            this.$router.replace({
                query: queryParams
            })

            this.loadCustomNodes();
        },

        getExecutionTypeLabel(code) {
            switch (code) {
                case 'custom_code':
                    return 'Código customizado';
                case 'http_request':
                    return 'Request HTTP';
                default:
                    return 'Desconhecido';
            }
        }
    }
};
</script>