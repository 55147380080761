<template>
    <div>
        <div class="row" id="flow-editor-page-container">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-9 col-xl-5 mb-lg-2">
                                <div class="">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Nome do flow"
                                        name="flow-name"
                                        v-model="flow.name"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-3 col-xl-auto">
                                <label class="form-check form-switch spacer-top">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        v-model="flow.enabled"
                                    />
                                    <span class="form-check-label">Ativo</span>
                                </label>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-primary" @click="saveFlow">
                                    <i class="ti ti-device-floppy"></i>
                                    Salvar e continuar
                                </button>
                            </div>
                            <div class="col-lg-12 col-xl-auto">
                                <div class="row text-right">
                                    <div class="col-auto">
                                        <button class="btn bg-red-lt" v-bind:disabled="!canExecute" @click="callFlowExecution()" data-toggle="popover" data-content="O fluxo deve conter um conector 'Manual Trigger' para execução manual" v-show="this.id !== 'new'">
                                            <i class="ti ti-bolt"></i>
                                            Executar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-xl-auto">
                                <div class="row text-right">
                                    <div class="col-auto">
                                        <div class="btn-group">
                                            <button class="btn" @click="showTemplateGallery">
                                                <i class="ti ti-template"></i>
                                                Templates
                                            </button>
                                            <button class="btn" @click="selectImportPosition()">
                                                <i class="ti ti-file-import"></i>
                                                Importar
                                            </button>
                                            <button class="btn" @click="exportFlow">
                                                <i class="ti ti-file-export"></i>
                                                Exportar
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn position-relative" @click="openVariables()" v-if="flow">
                                            <i class="ti ti-code"></i>
                                            Variáveis
                                            <span v-if="flow.variables && flow.variables.length" class="badge badge-notification badge-pill">{{ flow.variables.length }}</span>
                                        </button>
                                    </div>
                                    <div class="col-auto">
                                        <a @click="openDebugger()" class="btn bg-blue-lt" v-show="this.id !== 'new'">
                                            <i class="ti ti-radar-2"></i>
                                            Debugger
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col">
                                            <p>
                                                <span class="text-orange">Action/Trigger: {{ nodesCount }}</span>
                                                &nbsp;
                                                <span class="text-blue">Controle de fluxo: {{ nodesCountFlowcontrol }}</span>
                                                &nbsp;
                                                <span class="text-azure">Total de conectores: {{ nodesCount + nodesCountFlowcontrol }}</span>
                                            </p>
                                        </div>
                                        <div class="col-auto">
                                            <small v-show="flowUpdated" class="text-mutted">Existem alterações não salvas no fluxo</small>
                                            <small v-show="!flowUpdated" class="text-mutted"></small>
                                        </div>
                                        <div class="col-auto">
                                            <button @click="showFlowErrors" class="btn btn-sm bg-red-lt" v-if="flowErrors.length">
                                                <i class="ti ti-alert-triangle"></i>
                                                Erros encontrados no fluxo
                                            </button>
                                            <!-- <span class="text-mutted" v-if="!flowErrors.length">
                                                <i class="ti ti-check"></i> Nenhum problema encontrado no fluxo
                                            </span> -->
                                        </div>
                                    </div>

                                    <input
                                        type="hidden"
                                        v-model="flow.data"
                                    />
                                    <iframe
                                        src="/flow-editor/index.html"
                                        frameborder="0"
                                        id="flow-editor-iframe"
                                        @load="iframeLoaded"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FlowErrorsViewer :errors="flowErrors" :key="flowErrorsKey" v-if="flowErrorsKey" />
        <FlowDebugger v-if="showDebugger" :flow_id="flow._id" :workspace_id="workspace_id" :key="debugggerComponentKey" />
        <FlowTemplateGallery @selected-flow="selectImportPosition" :key="templateGalleryComponentKey" v-if="templateGalleryComponentKey" />
        <FlowManualTriggerForm :flow="flow" :description="manualTriggerFormDescription" :fields="manualTriggerFormFields" :key="showManualTriggerForm" v-if="showManualTriggerForm" />
        <CodeEditorModal :content="codeEditorContent" :language="codeEditorLanguage" :key="showCodeEditorModalKey" v-if="showCodeEditorModal" @changeContent="updateCodeEditorContent" />
        <FlowVariablesModal :initVariables="flow.variables" :key="showVariablesModalKey" v-if="showVariablesModal" @update="updateVariables" />
    </div>
</template>

<script>
import { store } from "../store";
import EventBus from "../services/event-bus";
import Api from "../services/api";
import EngineApi from "../services/engine-api";
import moment from 'moment';
import FlowErrorsViewer from "../components/FlowErrorsViewer.vue";
import FlowDebugger from "../components/FlowDebugger.vue";
import FlowTemplateGallery from "../components/FlowTemplateGallery.vue";
import FlowManualTriggerForm from "../components/FlowManualTriggerForm.vue";
import CodeEditorModal from '../components/CodeEditorModal.vue';
import FlowVariablesModal from '../components/FlowVariablesModal.vue';

export default {
    name: "FlowEditPage",
    data() {
        return {
            id: null,
            initialized: false,
            flowUpdated: false,
            flow: {},
            nodesCount: 0,
            nodesCountFlowcontrol: 0,
            flowErrors: [],
            iframeResponseReceived: false,
            iframeImageResponseReceived: false,
            iframeComponent: null,
            serviceAccounts: [],
            flowErrorsKey: null,
            showDebugger: false,
            debugggerComponentKey: null,
            workspace_id: null,
            templateGalleryComponentKey: null,
            manualTriggerFormFields: [],
            manualTriggerFormDescription: null,
            showManualTriggerForm: false,
            flowTriggers: [],
            codeEditorName: '',
            codeEditorContent: '',
            codeEditorLanguage: 'plaintext',
            showCodeEditorModal: false,
            showCodeEditorModalKey: null,
            showVariablesModal: false,
            showVariablesModalKey: null
        };
    },
    components: {
        FlowErrorsViewer,
        FlowDebugger,
        FlowTemplateGallery,
        FlowManualTriggerForm,
        CodeEditorModal,
        FlowVariablesModal
    },
    computed: {
        canExecute() {
            var can_execute = false;

            if (!this.flow.enabled) return false;
            if (!this.flowTriggers.length) return false;

            this.flowTriggers.forEach(nodeTrigger => {
                if(nodeTrigger.type == 'core_manual_trigger'){
                    can_execute = true
                }
            })

            return can_execute;
        }
    },
    async mounted() {
        this.id = this.$route.params.id;
        if (!this.id) {
            this.id = 'new';
        }

        store.showSidebar = true;
        store.sidebarSize = 'mini';
        store.showHeader = true;
        store.showBackButton = true;
        
        if (this.$route.query.fromNew) {
            store.backUrl = "/flows";
        }

        this.workspace_id = store.workspace._id;

        if (this.id == 'new') {
            // is new
            this.flow = {
                name: "",
                enabled: true,
                triggers: [],
                variables: [],
                data: null,
                workspace: this.workspace_id
            }

            this.showDebugger = false;
            localStorage.setItem('live-debugger', 0);
            document.body.classList.remove('live-debugger-active');
        } else {
            var responseData = await Api.flows.get(this.id);
            this.flow = responseData.data;
            
            // existing flows without variables
            if (this.flow.variables == undefined) {
                this.flow.variables = [];
            }
        }

        EventBus.emit("set-header-title", this.flow.name || "Criar novo fluxo");
        document.body.classList.add("flow-editor");

        // register iframe component
        this.iframeComponent = document.getElementById("flow-editor-iframe");

        // show live debugger if stored in local storage
        if (parseInt(localStorage.getItem("live-debugger"))) {
            this.openDebugger();
        }
    },
    methods: {
        async iframeLoaded(){
            this.iframeComponent = document.getElementById("flow-editor-iframe");

            var nodesLibraryResponse = await EngineApi.getNodesLibrary();
            var nodesLibrary = nodesLibraryResponse.data;

            var nodesLimit = parseInt(store.user.customer.config.node_limit);

            this.iframeComponent.contentWindow.initializeEditor(nodesLibrary.nodes, JSON.parse(this.flow.data), nodesLimit)

            // register external functions to call APIs
            this.iframeComponent.contentWindow.editor.registerExternalFunction('service-accounts-list', Api.serviceAccounts.listOptions)
            this.iframeComponent.contentWindow.editor.registerExternalFunction('slack-channels', Api.serviceAccounts.slack.getChannels)
            this.iframeComponent.contentWindow.editor.registerExternalFunction('trello-boards', Api.serviceAccounts.trello.getBoards)
            this.iframeComponent.contentWindow.editor.registerExternalFunction('trello-board-lists', Api.serviceAccounts.trello.getBoardLists)
            this.iframeComponent.contentWindow.editor.registerExternalFunction('database-table-list', async () => {
                let response = await Api.database.tables.listAll()
                return {data: response.data.items}
            })
            this.iframeComponent.contentWindow.editor.registerExternalFunction('queue-list', async () => {
                let response = await Api.queues.listAll()
                return {data: response.data.items}
            })
            this.iframeComponent.contentWindow.editor.registerExternalFunction('form-list', async () => {
                let response = await Api.forms.listAll()
                return {data: response.data.items}
            })

            this.iframeComponent.contentWindow.editor.addEventListener('flow-initialized', () => {
                this.initialized = true;
                this.nodesCount = this.iframeComponent.contentWindow.editor.getNodesCount(true)
                this.nodesCountFlowcontrol = this.iframeComponent.contentWindow.editor.getNodesCount() - this.nodesCount
                this.flowErrors = this.iframeComponent.contentWindow.editor.getErrors()
                this.flowTriggers = this.iframeComponent.contentWindow.editor.getTriggers()
            })
            this.iframeComponent.contentWindow.editor.addEventListener('flow-updated', () => {
                if (this.initialized) {
                    this.flowUpdated = true;
                }
                this.nodesCount = this.iframeComponent.contentWindow.editor.getNodesCount(true)
                this.nodesCountFlowcontrol = this.iframeComponent.contentWindow.editor.getNodesCount() - this.nodesCount
                this.flowErrors = this.iframeComponent.contentWindow.editor.getErrors()
                this.flowTriggers = this.iframeComponent.contentWindow.editor.getTriggers()
            })
            this.iframeComponent.contentWindow.editor.addEventListener('detached-code-editor', (data) => {
                this.openCodeEditorModal(data)
            })

            // get first errors
            this.flowErrors = this.iframeComponent.contentWindow.editor.getErrors()
        },

        getFlowImage() {
            return this.iframeComponent.contentWindow.editor.exportToImage()
        },

        saveFlow: async function (e) {
            EventBus.emit('ajax-request-start');
            var imageData = await this.getFlowImage();
            EventBus.emit('ajax-request-end');
            var dataJson = this.iframeComponent.contentWindow.editor.exportToJson();

            // save flow
            var promise;
            if(this.id == 'new'){
                this.flow.data = dataJson;
                this.flow.thumbnailData = imageData;
                promise = Api.flows.create(this.flow)
            } else {
                // send only fields to update
                var flowData = {}
                flowData.name = this.flow.name;
                flowData.thumbnailData = imageData;
                flowData.data = dataJson;
                flowData.enabled = this.flow.enabled;
                flowData.variables = this.flow.variables;

                promise = Api.flows.update(this.$route.params.id, flowData)
            }

            promise.then((response) => {
                    
                    EventBus.emit("message", {
                        type: "success",
                        message: "Fluxo salvo com sucesso",
                    });

                    this.flowUpdated = false;

                    if (this.id == 'new') {
                        this.$router.push('/flows/' + response.data._id + '?fromNew=true')
                    }
                })
                .catch((error) => {
                    var error_message = error.response.data.error ?? error;
                    EventBus.emit("message", {
                        type: "danger",
                        message: "Ocorreu um erro ao salvar o fluxo: " + error_message
                    });

                    this.flowUpdated = false;
                });

            e.preventDefault();
        },

        showFlowErrors() {
            this.flowErrorsKey = Math.random();
        },

        // execution in edit
        async callFlowExecution(){
            this.manualTriggerFormFields = []
            this.manualTriggerFormDescription = null
            this.showManualTriggerForm = false

            try {
                var response = await EngineApi.flows.execute(this.flow._id)

                if (!response.data.success && response.data.form_fields) {
                    this.manualTriggerFormFields = response.data.form_fields;
                    this.manualTriggerFormDescription = response.data.form_description;
                    this.showManualTriggerForm = Math.random();

                    return;
                }

                if (!this.showDebugger) {
                    EventBus.emit('message', {
                        type: 'success',
                        message: 'Flow iniciado! Verifique os logs de monitoramento para detalhes da execução.'
                    })
                }
            } catch (e) {
                EventBus.emit('message', {
                    type: 'danger',
                    message: 'Ocorreu um erro ao tentar executar o Flow!'
                })
            }
        },
        goToMonitoring(){
            var route = this.$router.resolve({
                path: '/monitoring',
                query: {
                    filters: JSON.stringify({
                        flow: this.flow._id,
                        date_alias: '1d'
                    })
                }
            })
            window.open(route.href)
        },
        openDebugger(){
            this.debugggerComponentKey = Math.random();

            if (this.showDebugger) {
                this.showDebugger = false;
                localStorage.setItem('live-debugger', 0);
                document.body.classList.remove('live-debugger-active');
                return;
            }

            document.body.classList.add('live-debugger-active');
            this.showDebugger = true;
            localStorage.setItem('live-debugger', 1);
        },
        slugfy(string) {
            return string
                .toLowerCase()
                .replace(/[^\w ]+/g, "")
                .replace(/ +/g, "-");
        },
        exportFlow() {
            var flowData = JSON.parse(this.iframeComponent.contentWindow.editor.exportToJson());
            flowData.data.exported_at = moment().format("YYYY-MM-DD HH:mm:ss");
            flowData.variables = this.flow.variables;

            var datetime = moment().format("YYYYMMDDHHmmss");

            var dataStr =
                "data:text/json;charset=utf-8," +
                encodeURIComponent(JSON.stringify(flowData));

            var fileName = this.slugfy(this.flow.name || 'untitled') + '-' + datetime + ".floui.json"
            var downloadAnchorNode = document.createElement("a");
            downloadAnchorNode.setAttribute("href", dataStr);
            downloadAnchorNode.setAttribute("download", fileName);
            document.body.appendChild(downloadAnchorNode); // required for firefox
            
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        },
        selectImportPosition(data = null) {
            EventBus.emit('message', {
                type: 'info',
                message: 'Selecione a posição no editor para importar o fluxo'
            })

            this.iframeComponent.contentWindow.editor.selectImportPosition((position) => {
                this.importFlow(position.x, position.y, data)
            });
        },
        importFlow(positionX, positionY, data = null){
            // data from argument
            if (data) {
                var flowData = JSON.parse(data);
                return this.iframeComponent.contentWindow.editor.importFlow(flowData, positionX, positionY);
            }

            var fileInput = document.createElement("input");
            fileInput.setAttribute("type", "file");
            fileInput.setAttribute("accept", ".json");
            fileInput.addEventListener("change", (e) => {
                var file = e.target.files[0];
                var reader = new FileReader();

                reader.onload = (e) => {
                    var flowData = JSON.parse(e.target.result);

                    EngineApi.flows.validateFlowData(store.workspace, flowData)
                        .then((response) => {
                            if (response.data.isValid) {
                                this.iframeComponent.contentWindow.editor.importFlow(flowData, positionX, positionY);

                                // import flow variables (append)
                                if (flowData.variables) {
                                    flowData.variables.forEach(variable => {
                                        this.flow.variables.push(variable)
                                    });
                                }

                                EventBus.emit("message", {
                                    type: "success",
                                    message: "Arquivo importado com sucesso"
                                });
                            } else {
                                EventBus.emit("message", {
                                    type: "danger",
                                    message: "O arquivo possui erros enão pode ser importado"
                                });
                            }
                        })
                        .catch((error) => {
                            EventBus.emit("message", {
                                type: "danger",
                                message: "Ocorreu um erro ao importar o fluxo: " + error
                            });
                        });
                };
                reader.readAsText(file);
            });
            document.body.appendChild(fileInput); // required for firefox
            fileInput.click();
            fileInput.remove();
        },

        showTemplateGallery() {
            this.templateGalleryComponentKey = Math.random();
        },

        openCodeEditorModal(data) {
            this.codeEditorContent = data.content
            this.codeEditorLanguage = data.language
            this.codeEditorName = data.name
            this.showCodeEditorModal = true
            this.showCodeEditorModalKey = Math.random()
        },
        updateCodeEditorContent(content) {
            this.iframeComponent.contentWindow.editor.dispatchEvent('update-code-editor-content', {
                content: content,
                name: this.codeEditorName
            })
        },

        openVariables() {
            this.showVariablesModal = true
            this.showVariablesModalKey = Math.random()
        },
        updateVariables(data) {
            this.flow.variables = data
        }
    },
};
</script>