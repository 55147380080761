<template>
    <div>
        <div id="live-debugger">
            <div class="header">
                <h5>
                    Live Debugger
                    <small v-if="logs.length" class="badge log-count">Exibindo últimos {{logs.length}} logs</small>
                </h5>
            </div>

            <div class="header-options">
                <div class="row">
                    <div class="col-9">
                        <label class="form-check form-switch spacer-top">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="includeAllLogs"
                            />
                            <span class="form-check-label">Incluir logs de monitoramento</span>
                        </label>
                    </div>
                    <div class="col-3">
                        <button class="btn btn-sm btn-secondary btn-clear" @click="clear">
                            <i class="ti ti-trash"></i>
                            Limpar logs
                        </button>
                    </div>
                </div>
            </div>

            <div class="card">
                <ol class="list-group">
                    <li class="list-group-item"
                        v-for="log in reversedLogs"
                        :key="log._id"
                        v-bind:data-node-uid="log.node_uid"
                        v-bind:data-execution-id="log.execution_id">

                        <div class="log-info">
                            <span>
                                <span class="timestamp">Data/hora: {{formatTimestamp(log.timestamp)}}</span>
                                <div v-if="log.type == 'debug'" class="badge badge-sm bg-orange text-orange-fg type">Type: {{log.type}}</div>
                                <div v-if="log.type != 'debug'" class="badge badge-sm bg-azure text-azure-fg type">Type: {{log.type}}</div>
                            </span>
                            <span v-if="log.node_uid" class="type">
                                Node ID: <button class="btn btn-sm" @click="hintNode(log.node_uid)">{{log.node_uid}}</button>
                            </span>
                            <span class="execution-id">Execution ID: {{log.execution_id}}</span>
                            <span class="label" v-if="log.label">Label: {{log.label}}</span>
                        </div>
                        <div class="log-message">
                            <pre class="message" v-if="!log.show_as_json">{{ log.message }}</pre>
                            <vue-json-pretty :data="log.message" v-if="log.show_as_json" />
                            <a class="btn btn-sm view-details" @click="showLogDetails(log)">
                                <i class="ti ti-eye"></i> visualizar
                            </a>
                            <a class="btn btn-sm copy" @click="copyData(log.message)">
                                <i class="ti ti-copy"></i> copiar
                            </a>
                        </div>
                    </li>
                </ol>
            </div>
        </div>
        <div
            class="modal modal-blur fade"
            id="modal-log-viewer"
            tabindex="-1"
            style="display: none"
            aria-modal="true"
            role="dialog">
            <div
                class="modal-dialog modal-xl"
                role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Visualizar detalhes do log</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="path-selector" v-show="selectedLog && selectedLog.show_as_json">
                            <div class="input-group">
                                <span class="input-group-text">
                                  JSON Path
                                </span>
                                <input type="text" v-model="selectedNodeData.path" readonly class="form-control json-path-input" placeholder="Selecione um parâmetro...">
                                <button class="btn copy" @click="copyData(selectedNodeData.path, '.json-path-input')" v-bind:disabled="!selectedNodeData.path">
                                    <i class="ti ti-copy"></i> copiar
                                </button>
                            </div>
                        </div>

                        <div class="log-container" v-if="selectedLog">
                            <pre class="message" v-if="!selectedLog.show_as_json">{{ selectedLog.message }}</pre>
                            <vue-json-pretty
                                :data="selectedLog.message"
                                :show-icon="true"
                                v-if="selectedLog.show_as_json"
                                :rootPath="'msg'"
                                @nodeClick="getSelectedNode"
                                />
                            <a class="btn btn-sm copy" @click="copyData(selectedLog.message)">
                                <i class="ti ti-copy"></i> copiar conteúdo
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { store } from '../store';

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    name: 'FlowDebugger',
    components: {
        VueJsonPretty
    },
    props: {
        flow_id: {
            type: String,
            required: true
        },
        workspace_id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            socket: null,
            logs: [],
            selectedLog: null,
            includeAllLogs: false,
            selectedNodeData: {}
        }
    },
    computed: {
        reversedLogs() {
            return this.logs.slice().reverse();
        }
    },
    async mounted() {
        var url = store.workspace.engine_url + 'debugger-' + this.flow_id;
        this.socket = io(url);

        this.socket.on('connect', () => {
            console.log('%c 🚀 Floui Live Debugger connected!', 'color: #206bc4');
        });

        this.socket.on('message', (data) => {
            this.appendLog(data);
        });

        this.socket.on('disconnect', () => {
            console.log('%cFloui Live Debugger disconnected!', 'color: gray');
        });

        this.$watch('selectedLog', () => {
            this.selectedNodeData = {};
        })
    },
    async unmounted() {
        this.socket.disconnect();
    },
    methods: {
        clear() {
            this.logs = [];
        },
        appendLog(log) {
            var maxLogs = 30;

            if (log.type != 'debug' && !this.includeAllLogs){
                return;
            }

            if (this.logs.length >= maxLogs) {
                this.logs.shift();
            }

            log.show_as_json = false;

            try {
                var tryToParse = true;
                var maxMessageSize = 1000;
                if (typeof log.message === 'string' && log.message.length > maxMessageSize) {
                    log.show_as_json = false;
                    tryToParse = false;
                }

                if (typeof log.message === 'object' && JSON.stringify(log.message).length > maxMessageSize) {
                    log.show_as_json = false;
                    tryToParse = false;
                }

                if (tryToParse) {
                    if (typeof log.message === 'string') {
                        let parsed = JSON.parse(log.message);

                        log.message = parsed;
                        log.show_as_json = true;
                    }
                    
                    if (typeof log.message === 'object') {
                        log.show_as_json = true;
                    }
                }
            } catch (e) { }

            this.logs.push(log);
        },
        formatTimestamp(timestamp) {
            var datetime = moment(timestamp).format('DD/MM/YYYY HH:mm:ss');
            return datetime;
        },
        formatMessage(message) {
            if (typeof message === 'object') {
                return JSON.stringify(message, null, 2);
            }
            return message;
        },
        copyData(content, elSelector) {
            if (typeof content === 'object') {
                content = JSON.stringify(content, null, 2);
            }

            if (!elSelector) {
                var el = document.createElement('textarea');
                el.value = content;
                document.body.appendChild(el);
            } else {
                var el = document.querySelector(elSelector);
            }

            el.select();
            el.setSelectionRange(0, 9999999999999);
            navigator.clipboard.writeText(el.value);
            
            if (!elSelector) {
                document.body.removeChild(el);
            }

            if (elSelector) {
                setTimeout(() => {
                    el.setSelectionRange(0, 0);
                    el.blur();
                }, 1000)
            }
        },
        hintNode(node_uid) {
            if (node_uid) {
                var iframeComponent = document.getElementById("flow-editor-iframe");
                iframeComponent.contentWindow.editor.hintNode(node_uid);
            }
        },
        showLogDetails(log){
            this.selectedLog = JSON.parse(JSON.stringify(log));

            var maxMessageSizeToParse = 100000;

            if (typeof this.selectedLog.message == 'string' && !this.selectedLog.show_as_json) {
                try {
                    var tryToParse = true;

                    if (typeof this.selectedLog.message === 'string' && this.selectedLog.message.length > maxMessageSizeToParse) {
                        tryToParse = false;
                    }

                    if (tryToParse) {
                        let parsed = JSON.parse(this.selectedLog.message);

                        this.selectedLog.message = parsed;
                        this.selectedLog.show_as_json = true;
                    }
                } catch (e) { }
            }

            if (typeof this.selectedLog.message == 'object') {
                this.selectedLog.show_as_json = true;

                if (JSON.stringify(this.selectedLog.message).length > maxMessageSizeToParse){
                    this.selectedLog.show_as_json = false;
                }
            }

            var modal = new bootstrap.Modal(document.getElementById('modal-log-viewer'), { keyboard: false })
            modal.show()
        },
        getSelectedNode(node){
            this.selectedNodeData = node
        }
    }
}
</script>