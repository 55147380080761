<template>
    <div
        class="modal modal-blur fade"
        id="modal-code-editor"
        tabindex="-1"
        aria-modal="true"
        role="dialog">
        <div
            class="modal-dialog modal-xl"
            role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Code Editor</h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close">
                    </button>
                </div>
                <div class="modal-body pt-2">
                    <div class="modal-body-container">
                        <div class="row mb-2">
                            <div class="col">
                                <div class="row">
                                    <div class="col-1">
                                        <select v-model="customLanguage" class="form-select">
                                            <option value="json">json</option>
                                            <option value="xml">xml</option>
                                            <option value="javascript">javascript</option>
                                            <option value="html">html</option>
                                            <option value="css">css</option>
                                            <option value="plaintext">text</option>
                                            <option value="sql">sql</option>
                                        </select>
                                    </div>
                                    <div class="col-1">
                                        <button class="btn" @click="showCommandPalette()">
                                            <i class="ti ti-keyboard"></i>
                                            Actions
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <button class="btn" @click="enterFullScreen()">
                                    <i class="ti ti-arrows-maximize"></i>
                                    Tela cheia
                                </button>
                            </div>
                        </div>
                        <div id="monacoEditorContainer" style="height: 70vh"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.text-right {
    text-align: right;
}
</style>
<script>
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

export default {
    name: 'CodeEditorModal',
    props: {
        content: {
            type: String,
            default: ''
        },
        language: {
            type: String,
            default: 'plaintext'
        }
    },
    data() {
        return {
            editor: null,
            customLanguage: 'plaintext',
            modal: null
        }
    },
    mounted(){
        var modal = new bootstrap.Modal(document.getElementById('modal-code-editor'), {
            backdrop: 'static',
            keyboard: false
        })
        modal.show()
        document.getElementById('modal-code-editor').addEventListener('hidden.bs.modal', () => {
            this.updateContent()
        })

        var language = this.language;
        if (language === 'text') {
            language = 'plaintext';
        }

        this.customLanguage = language;
        this.initEditor()
    },
    methods: {
        initEditor: function(){
            var editor = monaco.editor.create(document.getElementById('monacoEditorContainer'), {
                value: this.content,
                theme: "vs-dark",
                language: this.customLanguage,
                automaticLayout: true,
                contextmenu: true,
                minimap: {
                    enabled: true
                },
                padding: {
                    top: 10
                }
            });

            // add custom commands to editor
            editor.addAction({
                id: 'OPEN_MODAL_WINDOW',
                label: 'Open modal window',
                run: (ed) => {
                    // this.setState({showPopup: true})
                    console.log('run OPEN_MODAL_WINDOW')
                },
            })
            
            this.$watch('customLanguage', () => {
                monaco.editor.setModelLanguage(editor.getModel(), this.customLanguage);
            })

            // set monaco editor to global variable to prevent memory leaks on Vue
            window.monacoEditor = editor;

            // register new global shortcut (Ctrl/CMD + Shift + P)
            window.addEventListener('keydown', (e) => {
                if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.code === 'KeyP') {
                    this.showCommandPalette();
                }
            })
        },
        enterFullScreen: function() {
            var elem = document.getElementById('monacoEditorContainer');
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
        },
        showCommandPalette() {
            window.monacoEditor.focus(); // Editor needs focus to be able to trigger command
            window.monacoEditor.trigger("", "editor.action.quickCommand", "");
        },
        updateContent() {
            this.$emit('changeContent', window.monacoEditor.getValue())
        }
    }
}
</script>