<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="col">
                    <h3 class="card-title">
                        <i class="ti ti-cpu"></i>
                        Filas internas
                    </h3>
                    <p class="text-muted">Gestão de filas internas do workspace com Redis</p>
                </div>
                <div class="col-auto">
                    <a href="/queues/new" class="btn btn-primary">
                        <i class="ti ti-plus"></i>
                        Criar nova fila
                    </a>
                </div>
            </div>
            
            <div class="card-body">
                <div class="row">
                    <div class="col-3 mb-2">
                        <input type="text" class="form-control" v-model="filter.query" @change="updateFilter" placeholder="Buscar filas">
                    </div>
                </div>
            </div>
            
            <div class="card-body">
                <div v-if="queueList.length">
                    <table class="table table-vcenter card-table">
                        <thead>
                            <tr>
                                <th>Nome da fila</th>
                                <th>Status</th>
                                <th>Criação</th>
                                <th>Atualização</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="queue in queueList" :key="queue._id">
                                <td>{{queue.name}}</td>
                                <td>
                                    <button class="btn btn-sm" v-if="queue.status == 'paused'" @click="resume(queue._id)">
                                        <i class="ti ti-player-play-filled"></i>
                                        Iniciar consumo
                                    </button>
                                    <button class="btn btn-sm" v-if="queue.status == 'running'" @click="pause(queue._id)">
                                        <i class="ti ti-player-pause-filled"></i>
                                        Pausar consumo
                                    </button>
                                    <span>&nbsp;</span>
                                    <span class="badge bg-green text-green-fg" v-if="queue.status == 'running'">{{queue.status}}</span>
                                    <span class="badge bg-azure" v-if="queue.status == 'paused'">{{queue.status}}</span>
                                    <span class="badge" v-if="queue.status == 'waiting'">{{queue.status}}</span>
                                </td>
                                <td>{{formatDate(queue.created_at)}}</td>
                                <td>{{formatDate(queue.updated_at)}}</td>
                                <td>
                                    <span>&nbsp;</span>
                                    <a :href="'/queues/view/' + queue._id" class="btn btn-sm">
                                        <i class="ti ti-eye"></i>
                                        Visualizar
                                    </a>
                                    <span>&nbsp;</span>
                                    <a :href="'/queues/edit/' + queue._id" class="btn btn-sm btn-ghost-secondary">
                                        <i class="ti ti-pencil"></i>
                                        Editar
                                    </a>
                                    <span>&nbsp;</span>
                                    <a :href="'/queues/delete/' + queue._id" class="btn btn-sm btn-ghost-danger">
                                        <i class="ti ti-trash"></i>
                                        Excluir
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card-body" v-if="!queueList.length && !filter.query">
                <div class="empty">
                    <p class="empty-title">
                        Nenhuma fila criada ainda.
                    </p>
                    <p class="empty-subtitle text-muted">
                        Clique no botão abaixo para criar sua primeira fila!
                    </p>
                    <div class="empty-action">
                        <a href="/queues/new" class="btn btn-primary">
                            <i class="ti ti-plus"></i>
                            Criar fila
                        </a>
                    </div>
                </div>
            </div>

            <div class="card-body" v-if="!queueList.length && filter.query">
                <div class="empty bg-muted-lt">
                    <p class="empty-title">
                        Nenhuma fila encontrada para esta busca.
                    </p>
                </div>
            </div>

            <div class="card-footer">
                <Pagination :key="paginationKey" :showPerPage="showPerPage" :currentPage="currentPage" :total="total" v-if="total > showPerPage" @pageChange="updatePage" />
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import Api from '../services/api'
import Pagination from './Pagination'
import EventBus from '../services/event-bus'

export default {
    name: 'QueuesList',
    components: {
        Pagination
    },
    data() {
        return {
            queueList: [],
            filter: {
                query: ''
            },
            paginationKey: 0,
            showPerPage: 10,
            currentPage: 1,
            total: 0
        }
    },
    async mounted() {
        this.currentPage = parseInt(this.$route.query.page) || 1;
        this.filter.query = this.$route.query.query || "";

        this.loadQueues();
    },
    methods: {
        async loadQueues(){
            this.paginationKey = Math.random();

            const response = await Api.queues.list(this.currentPage, this.showPerPage, this.filter)

            this.queueList = response.data.items;
            this.total = response.data.total;
        },
        async updatePage(page) {
            this.$router.replace({
                query: {
                    page: page,
                    query: this.filter.query
                }
            });

            this.currentPage = page;
            this.loadQueues();
        },
        updateFilter() {
            this.$router.replace({
                query: {
                    page: 1,
                    query: this.filter.query
                }
            });
            
            this.loadQueues();
        },
        formatDate(date) {
            return moment(date).format('DD/MM/YYYY HH:mm:ss')
        },
        async pause(queueId) {
            try {
                await Api.queues.pause(queueId);
                await this.loadQueues();
            } catch (error) {
                console.log(error);
            }
        },
        async resume(queueId) {
            try {
                await Api.queues.resume(queueId);
                await this.loadQueues();
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>