import axios from 'axios';
import EventBus from './event-bus';

const engineApiAxiosInstance = axios.create();

engineApiAxiosInstance.defaults.baseURL = process.env.VUE_APP_ENGINE_URL;
engineApiAxiosInstance.interceptors.request.use((request) => {
    if (localStorage.getItem('token')) {
        request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
    }
    return request
})

// redirect to login page when receiving 403 response
engineApiAxiosInstance.interceptors.response.use((response) => {
    return response
}, (error) => {
    if (error.response.status === 403) {
        EventBus.emit('call-logout')
    }

    EventBus.emit('ajax-request-end');
    return Promise.reject(error)
})


// call event bus when ajax start
engineApiAxiosInstance.interceptors.request.use((request) => {
    if (!engineApiAxiosInstance._disable_loaders){
        EventBus.emit('ajax-request-start');
    }
    return request
})

// call event bus when ajax end
engineApiAxiosInstance.interceptors.response.use((response) => {
    engineApiAxiosInstance._disable_loaders = false;
    EventBus.emit('ajax-request-end');
    return response
})

export default {
    /**
     * Get Nodes Library
     * @returns {Promise<Array<Object>>}
     **/
    getNodesLibrary: async () => {
        return engineApiAxiosInstance.get('library')
    },

    flows: {
        /**
         * Validate Flow Data on workspace engine
         * @param {Object} workspace
         * @param {Object} flow_data
         * @returns 
         */
        validateFlowData: async (workspace, flow_data) => {
            return engineApiAxiosInstance.post('schema-validator/flow-data', flow_data)
        },

        /**
         * Call flow execution (with manual trigger) by id
         * @param {String} flow_id
         * @returns {Promise<Object>}
         */
        execute: async (flow_id) => {
            return engineApiAxiosInstance.get('flow/manual-trigger/' + flow_id);
        },

        /**
         * Call flow execution (with manual trigger) by id
         * @param {String} workspace_id
         * @param {String} flow_id
         * @param {Object} form_data
         * @returns {Promise<Object>}
         */
        executeWithForm: async (flow_id, form_data) => {
            return engineApiAxiosInstance.post('flow/manual-trigger/' + flow_id, form_data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        }
    }
}