<template>
  <div class="page">
      <aside class="navbar navbar-vertical navbar-expand-sm navbar-dark" data-bs-theme="dark" v-bind:class="store.sidebarSize" v-if="store.showSidebar">
        <div class="container-fluid">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
            <span class="navbar-toggler-icon"></span>
          </button>
          <SidebarMenu />
        </div>
      </aside>
      <div class="page-wrapper">
        <Header v-if="store.showHeader" />
        <!-- <div class="page-body"> -->
          <div class="container-fluid mt-2">
            <Messages />
            <router-view :key="$route.fullPath"></router-view>
          </div>
        <!-- </div> -->
      </div>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import SidebarMenu from './components/SidebarMenu.vue';
import { store } from './store';
import Messages from './components/Messages';

export default {
  name: 'App',
  data() {
    return {
      store
    }
  },
  components: {
    Header,
    SidebarMenu,
    Messages
  }
}
</script>
