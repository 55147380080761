<template>
    <div>
        <div
            class="modal modal-blur fade"
            :id="'modal-widget-options-' + widget.id"
            tabindex="-1"
            aria-modal="true"
            role="dialog">
            <div
                class="modal-dialog modal-xl"
                role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Configuração do widget</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body pt-2">
                        <div class="row mb-3" v-for="param in params" :key="param.name">

                            <div class="col separator" v-if="param.type == 'separator'">
                                <hr />
                            </div>
                            
                            <div class="col-4" v-if="param.type == 'flow-selector'">
                                <label class="form-label required">{{param.label}}</label>
                                <select class="form-select" v-model="config[param.name]">
                                    <option value="">Todos os Flows</option>
                                    <option v-for="flow in flows" :value="flow._id" :key="flow._id">{{ flow.name }}</option>
                                </select>
                            </div>

                            <div class="col-4" v-if="param.type == 'table-selector'">
                                <label class="form-label required">{{param.label}}</label>
                                <select class="form-select" v-model="config[param.name]">
                                    <option v-for="table in tables" :value="table._id" :key="table._id">{{ table.name }}</option>
                                </select>
                            </div>

                            <div class="col-4" v-if="param.type == 'table-column-selector'">
                                <label class="form-label required">{{param.label}}</label>
                                
                                <label class="form-check form-switch spacer-top" v-for="field in tableFields" :key="field.name">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        v-model="config[param.name]"
                                        :true-value="[]"
                                        :value="field.name"
                                        @change="reorderColumnsSelection(param)"
                                    />
                                    <span class="form-check-label">{{field.label}}</span>
                                </label>
                            </div>

                            <div class="col-12" v-if="param.type == 'period'">
                                <label class="form-label required">{{param.label}}</label>
                                <div class="form-selectgroup">
                                    <label class="form-selectgroup-item" v-if="param.allow_all">
                                        <input type="radio" :name="'period-' + widget.id" v-model="config[param.name]" value="all" class="form-selectgroup-input">
                                        <span class="form-selectgroup-label">Não filtrar</span>
                                    </label>
                                    <label class="form-selectgroup-item">
                                        <input type="radio" :name="'period-' + widget.id" v-model="config[param.name]" value="dashboard" class="form-selectgroup-input">
                                        <span class="form-selectgroup-label">Seleção do usuário</span>
                                    </label>
                                    <label class="form-selectgroup-item">
                                        <input type="radio" :name="'period-' + widget.id" v-model="config[param.name]" value="today" class="form-selectgroup-input">
                                        <span class="form-selectgroup-label">Hoje</span>
                                    </label>
                                    <label class="form-selectgroup-item">
                                        <input type="radio" :name="'period-' + widget.id" v-model="config[param.name]" value="1_day" class="form-selectgroup-input">
                                        <span class="form-selectgroup-label">Últimas 24 horas</span>
                                    </label>
                                    <label class="form-selectgroup-item">
                                        <input type="radio" :name="'period-' + widget.id" v-model="config[param.name]" value="7_day" class="form-selectgroup-input">
                                        <span class="form-selectgroup-label">7 dias</span>
                                    </label>
                                    <label class="form-selectgroup-item">
                                        <input type="radio" :name="'period-' + widget.id" v-model="config[param.name]" value="15_day" class="form-selectgroup-input">
                                        <span class="form-selectgroup-label">15 dias</span>
                                    </label>
                                    <label class="form-selectgroup-item">
                                        <input type="radio" :name="'period-' + widget.id" v-model="config[param.name]" value="30_day" class="form-selectgroup-input">
                                        <span class="form-selectgroup-label">30 dias</span>
                                    </label>
                                    <label class="form-selectgroup-item">
                                        <input type="radio" :name="'period-' + widget.id" v-model="config[param.name]" value="custom" class="form-selectgroup-input">
                                        <span class="form-selectgroup-label">Personalizado</span>
                                    </label>
                                </div>
                            </div>

                            <div class="col-3 mt-3" v-if="param.type == 'period' && config[param.name] == 'custom'">
                                <label class="form-label required">De</label>
                                <input type="datetime-local" class="form-control" v-model="config.start_date">
                            </div>
                            <div class="col-3 mt-3" v-if="param.type == 'period' && config[param.name] == 'custom'">
                                <label class="form-label required">Até</label>
                                <input type="datetime-local" class="form-control" v-model="config.end_date">
                            </div>

                            <div class="col" v-if="param.type == 'radio'">
                                <label class="form-label required">{{param.label}}</label>
                                <div class="form-selectgroup">
                                    <label class="form-selectgroup-item" v-for="option in param.options" :key="option.value">
                                        <input type="radio" :name="param.name + '-' + widget.id" v-model="config[param.name]" :value="option.value" class="form-selectgroup-input">
                                        <span class="form-selectgroup-label">{{option.label}}</span>
                                    </label>
                                </div>
                            </div>

                            <div class="col-4" v-if="param.type == 'boolean'">
                                <label class="form-check form-switch spacer-top">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        v-model="config[param.name]"
                                    />
                                    <span class="form-check-label">{{param.label}}</span>
                                </label>
                            </div>

                            <div class="col-4" v-if="param.type == 'text'">
                                <label class="form-label required">{{param.label}}</label>
                                <input type="text" class="form-control" v-model="config[param.name]" :placeholder="param.placeholder">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.separator {
   margin: -25px 0;
}
</style>

<script>
import Api from '../../services/api'

export default {
    name: 'WidgetConfigModal',
    props: {
        widget: Object,
        defaults: Object,
        params: Array,
        flows: Array,
        tables: Array
    },
    data() {
        return {
            modalWidgetOptions: null,
            config: {},
            tableFields: []
        }
    },
    mounted() {
        this.config = this.defaults

        this.modalWidgetOptions = new bootstrap.Modal(document.getElementById('modal-widget-options-' + this.widget.id), {
            keyboard: false
        })
        this.modalWidgetOptions.show()

        this.$watch('config', this.updateConfig, { deep: true })
        this.updateConfig()

        // if has a config of type = 'table-column-selector', watch for config.table_id changes
        this.params.forEach(param => {
            if (param.type == 'table-column-selector') {
                this.loadTableFields(param)
                return this.$watch(`config.table_id`, () => this.loadTableFields(param))
            }
        })

        // refresh data
        this.params.forEach(param => {
            if (param.refresh_on_update) {
                this.$watch(`config.${param.name}`, this.refreshData)

                if (param.type == 'period') {
                    this.$watch(`config.start_date`, this.refreshData)
                    this.$watch(`config.end_date`, this.refreshData)
                }
            }
        })
    },
    methods: {
        updateConfig() {
            this.$emit('updateConfig', this.config)
        },
        refreshData() {
            this.$emit('refreshData')
        },
        async loadTableFields(param) {
            this.tableFields = []
            this.config[param.name] = this.config[param.name] || []

            if (!this.config.table_id) {
                return
            }

            try {
                var response = await Api.database.tables.get(this.config.table_id)
                this.tableFields = [
                    {name: '_id', label: 'ID', type: 'string'},
                    ...response.data.fields,
                    {name: 'created_at', label: 'Criação', type: 'datetime'},
                    {name: 'updated_at', label: 'Atualização', type: 'datetime'}
                ]
            } catch (error) {
                console.log(error)
            }
        },
        reorderColumnsSelection(param) {
            // reorder selected columns to equalize from tableFields
            this.config[param.name] = this.config[param.name].sort((a, b) => {
                return this.tableFields.findIndex(field => field.name == a) - this.tableFields.findIndex(field => field.name == b)
            })
        }
    }
}
</script>