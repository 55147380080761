<template>
    <div>
        <div class="row mb-3">
            <div class="col">
                <label class="form-label">
                    <i class="ti ti-calendar"></i>
                    Período
                </label>
                <div class="form-selectgroup">
                    <label class="form-selectgroup-item">
                        <input type="radio" name="period" v-model="periodType" value="1h" class="form-selectgroup-input">
                        <span class="form-selectgroup-label">Última 1 hora</span>
                    </label>
                    <label class="form-selectgroup-item">
                        <input type="radio" name="period" v-model="periodType" value="1d" class="form-selectgroup-input">
                        <span class="form-selectgroup-label">Últimas 24 horas</span>
                    </label>
                    <label class="form-selectgroup-item">
                        <input type="radio" name="period" v-model="periodType" value="7d" class="form-selectgroup-input">
                        <span class="form-selectgroup-label">7 dias</span>
                    </label>
                    <label class="form-selectgroup-item">
                        <input type="radio" name="period" v-model="periodType" value="15d" class="form-selectgroup-input">
                        <span class="form-selectgroup-label">15 dias</span>
                    </label>
                    <label class="form-selectgroup-item">
                        <input type="radio" name="period" v-model="periodType" value="1000d" class="form-selectgroup-input">
                        <span class="form-selectgroup-label">Todos</span>
                    </label>
                    <!-- <label class="form-selectgroup-item">
                        <input type="radio" name="period" v-model="periodType" value="custom" class="form-selectgroup-input">
                        <span class="form-selectgroup-label">Customizado</span>
                    </label>
                    <v-date-picker
                        v-if="periodType === 'custom'"
                        v-model="range"
                        mode="dateTime"
                        is-range
                        v-bind:is24hr="true">
                        <template v-slot="{ inputValue, inputEvents, isDragging }">
                            <div class="row mx-2">
                                <div class="col">
                                    <input class="form-control"
                                        :class="isDragging ? 'text-red' : ''"
                                        :value="inputValue.start"
                                        v-on="inputEvents.start"
                                    />
                                </div>
                                <div class="col">
                                    <input class="form-control"
                                        :class="isDragging ? 'text-red' : ''"
                                        :value="inputValue.end"
                                        v-on="inputEvents.end"
                                    />
                                </div>
                            </div>
                        </template>
                    </v-date-picker> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DateRangePicker",
    props: {
        periodTypeDefault: {
            type: String,
            default: '1h'
        }
    },
    data() {
        return {
            periodType: '1d',
            showDatePicker: false,
        }
    },
    mounted(){
        this.$watch('periodTypeDefault', function (value) {
            this.periodType = value;
        });

        this.periodType = this.periodTypeDefault
        this.$watch('periodType', (value) => {
            this.$emit('periodChange', value)
        });
    }
}
</script>