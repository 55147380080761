<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="col">
                    <h3 class="card-title">
                        <i class="ti ti-cpu"></i>
                        Flows do workspace
                    </h3>
                </div>
                <div class="col-auto">
                    <a href="/flows/new" class="btn btn-primary">
                        <i class="ti ti-plus"></i>
                        Criar Flow
                    </a>
                </div>
            </div>
            
            <div class="card-body">
                <div class="row">
                    <div class="col-3 mb-2">
                        <input type="text" class="form-control" v-model="filter.query" @change="updateFilter" placeholder="Buscar flows">
                    </div>
                    <div class="col-3 mb-2">
                        <div class="form-selectgroup">
                            <label class="form-selectgroup-item">
                                <input type="radio" name="show_status" v-model="filter.show_status" value="all" class="form-selectgroup-input">
                                <span class="form-selectgroup-label">Todos</span>
                            </label>
                            <label class="form-selectgroup-item">
                                <input type="radio" name="show_status" v-model="filter.show_status" value="enabled" class="form-selectgroup-input">
                                <span class="form-selectgroup-label">Ativos</span>
                            </label>
                            <label class="form-selectgroup-item">
                                <input type="radio" name="show_status" v-model="filter.show_status" value="disabled" class="form-selectgroup-input">
                                <span class="form-selectgroup-label">Inativos</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            
            <div v-if="flowsList.length">
                <table class="table table-vcenter card-table">
                    <thead>
                        <tr>
                            <th>Preview</th>
                            <th>Nome</th>
                            <th>Status</th>
                            <!-- <th>Criação</th>
                            <th>Atualização</th> -->
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="flow in flowsList" v-bind:key="flow._id">
                            <td width="130">
                                <a v-bind:href="'/flows/' + flow._id">
                                    <div class="thumbnail-mask" v-if="flow.thumbnailData">
                                        <img v-bind:src="flow.thumbnailData" />
                                    </div>
                                    <div class="thumbnail-no-image" v-if="!flow.thumbnailData">
                                        Preview não disponível
                                    </div>
                                </a>
                            </td>
                            <td>
                                <a v-bind:href="'/flows/' + flow._id" class="name-link">
                                    <strong>{{flow.name}}</strong>
                                    <span v-if="flow.triggers" class="triggers-info">
                                        <small v-for="t in flow.triggers" :key="t.node_uid" class="badge" data-bs-toggle="tooltip" v-bind:data-bs-original-title="'Trigger do tipo' + t.node_type">{{t.node_type}}</small>
                                    </span>
                                    <small class="updated-at text-muted">
                                        Última atualização: {{ formatDate(flow.updated_at) }}
                                    </small>
                                </a>
                            </td>
                            <td>
                                <span v-if="flow.enabled" class="badge bg-green text-green-fg">Ativo</span>
                                <span v-else class="badge bg-red text-red-fg">Inativo</span>
                            </td>
                            <td>
                                <span class="dropdown">
                                    <button class="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown">
                                        Opções
                                    </button>
                                    <div class="dropdown-menu">
                                        <a v-bind:href="'/flows/' + flow._id" class="dropdown-item">
                                            <i class="ti ti-pencil"></i>
                                            Editar
                                        </a>
                                        <a href="#" @click="(e) => { duplicateFlow(e, flow) }" class="dropdown-item">
                                            <i class="ti ti-copy"></i>
                                            Duplicar flow
                                        </a>
                                        <a v-bind:href="'/flows/delete/' + flow._id" class="dropdown-item bg-danger-lt">
                                            <i class="ti ti-trash"></i>
                                            Remover
                                        </a>
                                    </div>
                                </span>
                                <span>&nbsp;</span>
                                <a @click="goToMonitoring(flow)" class="btn btn-sm" target="_blank">
                                    <i class="ti ti-list"></i>
                                    Logs de monitoramento
                                </a>
                                <span>&nbsp;</span>
                                <button class="btn btn-sm bg-red-lt" v-if="hasManualTrigger(flow)" @click="callFlowExecution(flow)">
                                    <i class="ti ti-bolt"></i>
                                    Executar
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="card-body" v-if="!flowsList.length && !filter.query">
                <div class="empty">
                    <p class="empty-title">
                        Nenhum Flow criado ainda.
                    </p>
                    <p class="empty-subtitle text-muted">
                        Clique no botão abaixo para criar seu primeiro Flow!
                    </p>
                    <div class="empty-action">
                        <a href="/flows/new" class="btn btn-primary">
                            <i class="ti ti-plus"></i>
                            Criar Flow
                        </a>
                    </div>
                </div>
            </div>

            <div class="card-body" v-if="!flowsList.length && filter.query">
                <div class="empty bg-muted-lt">
                    <p class="empty-title">
                        Nenhum Flow encontrado para esta busca.
                    </p>
                </div>
            </div>

            <div class="card-footer">
                <Pagination :key="paginationKey" :showPerPage="showPerPage" :currentPage="currentPage" :total="total" v-if="total" @pageChange="updatePage" />
            </div>
        </div>
        <FlowManualTriggerForm :flow="selectedFlow" :description="manualTriggerFormDescription" :fields="manualTriggerFormFields" :key="showManualTriggerForm" v-if="showManualTriggerForm" />
    </div>
</template>

<style scoped>
.name-link {
    color: #333;
    text-decoration: none;
}
.triggers-info {
    display: block;
    font-size: 12px;
    margin: 10px 0 10px 0;
}
.triggers-info .badge {
    color: #333;
    background: #fff3b9;
    margin-right: 5px;
    opacity: 0.7;
}
.name-link .updated-at {
    font-size: 11px;
    color: rgb(171, 169, 169)
}
</style>

<script>
import Api from "../services/api";
import EngineApi from "../services/engine-api";
import EventBus from "../services/event-bus";
import moment from "moment";
import Pagination from "./Pagination";
import FlowManualTriggerForm from "./FlowManualTriggerForm";

export default {
    name: "FlowsList",
    components: {
        Pagination,
        FlowManualTriggerForm
    },
    data() {
        return {
            paginationKey: null,
            showPerPage: 10,
            currentPage: 1,
            total: 0,
            flowsList: [],
            filter: {
                show_status: 'all',
                query: ""
            },
            manualTriggerFormFields: [],
            manualTriggerFormDescription: null,
            showManualTriggerForm: false,
            selectedFlow: null
        }
    },
    props: {
        workspace: {
            type: Object,
            required: true
        }
    },
    async mounted() {
        this.currentPage = parseInt(this.$route.query.page) || 1;
        this.filter.query = this.$route.query.query || "";
        this.filter.show_status = this.$route.query.show_status || "all";
        this.loadFlows();

        this.$watch('filter.show_status', this.updateFilter);
    },
    methods: {
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss");
        },
        async loadFlows(){
            this.paginationKey = Math.random();

            var responseFlows = await Api.flows.list(this.currentPage, this.showPerPage, this.filter);

            this.flowsList = responseFlows.data.items;
            this.total = responseFlows.data.total;
        },
        updateFilter() {
            this.$router.replace({
                query: {
                    page: 1,
                    query: this.filter.query,
                    show_status: this.filter.show_status
                }
            });
            
            this.loadFlows();
        },
        async duplicateFlow(e, flow) {
            e.preventDefault();

            if (confirm("Deseja realmente duplicar este flow? A cópia será criada desabilitada.")) {
                await Api.flows.duplicate(flow._id)

                EventBus.emit('message', {
                    type: 'success',
                    message: 'Flow duplicado com sucesso!'
                })
                
                this.$router.go()
            }
        },
        async toggleEnabled(flow){
            e.preventDefault();
        },
        hasManualTrigger(flow){
            var has_manual_trigger;
            flow.triggers.forEach(trigger => {
                if(trigger.node_type == 'core_manual_trigger'){
                    has_manual_trigger = true
                }
            })
            return has_manual_trigger;
        },

        async callFlowExecution(flow){
            this.manualTriggerFormFields = []
            this.manualTriggerFormDescription = null
            this.showManualTriggerForm = false

            try {
                var responseFlow = await Api.flows.get(flow._id)
                this.selectedFlow = responseFlow.data

                var response = await EngineApi.flows.execute(this.selectedFlow._id)

                if (!response.data.success && response.data.form_fields) {
                    this.manualTriggerFormFields = response.data.form_fields;
                    this.manualTriggerFormDescription = response.data.form_description;
                    this.showManualTriggerForm = Math.random();
                    return;
                }

                EventBus.emit('message', {
                    type: 'success',
                    message: 'Flow iniciado! Verifique os logs de monitoramento para detalhes da execução.'
                })
            } catch (e) {
                EventBus.emit('message', {
                    type: 'danger',
                    message: 'Ocorreu um erro ao tentar executar o Flow!'
                })
            }
        },

        goToMonitoring(flow){
            var route = this.$router.resolve({
                path: '/monitoring',
                query: {
                    filters: JSON.stringify({
                        flow: flow._id,
                        date_alias: '1d'
                    })
                }
            })
            window.open(route.href)
        },
        updatePage(page) {
            this.currentPage = page;
            this.paginationKey = Math.random();

            var queryParams = {
                page: page
            }

            if (this.filter.query) queryParams.query = this.filter.query;
            if (this.filter.show_status) queryParams.show_status = this.filter.show_status;

            this.$router.replace({
                query: queryParams
            })

            this.loadFlows();
        }
    }
}
</script>