<template>
    <div
        class="modal modal-blur fade"
        id="modal-flow-variables"
        tabindex="-1"
        aria-modal="true"
        role="dialog">
        <div
            class="modal-dialog modal-xl"
            role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Variáveis do Flow</h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close">
                    </button>
                </div>
                <div class="modal-body pt-2">
                    <div class="modal-body-container">

                        <p class="alert alert-warning">
                            <strong>Importante:</strong> As variáveis do Flow (variáveis locais) podem sobrescrever variáveis do Workspace ou do sistema quando utilizados códigos iguais.
                        </p>

                        <div class="card">
                            <table class="table table-hovered table-variables">
                                <thead>
                                    <tr>
                                        <th>Código da variável (key)</th>
                                        <th>Conteúdo da variável (value)</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, index) in variables" :key="row">
                                        <td>
                                            <input v-model="row.key" class="form-control" placeholder="Chave" />
                                        </td>
                                        <td>
                                            <input v-model="row.value" class="form-control" placeholder="Valor" />
                                        </td>
                                        <td>
                                            <button class="btn btn-sm btn-ghost-red" @click="removeRow(index)">
                                                <i class="ti ti-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="card-footer">
                                <button class="btn btn-sm" @click="addRow()">
                                    <i class="ti ti-plus"></i>
                                    Adicionar nova
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.table-variables {
    margin-bottom: 0
}
</style>

<script>
export default {
    name: 'FlowVariablesModal',
    props: {
        initVariables: {
            type: Array
        }
    },
    data() {
        return {
            variables: []
        }
    },
    mounted(){
        var modal = new bootstrap.Modal(document.getElementById('modal-flow-variables'), {
            keyboard: false
        })
        modal.show()

        if (this.initVariables) {
            this.variables = this.initVariables
        }
    },
    methods: {
        addRow() {
            this.variables.push({
                key: '',
                value: ''
            })

            this.$emit('update', this.variables)
        },
        removeRow(index) {
            this.variables.splice(index, 1)

            this.$emit('update', this.variables)
        }
    }
}
</script>