import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue'
import EventBus from './services/event-bus';
import Api from './services/api';
import AccountApi from './services/account-api';
import VueApexCharts from "vue3-apexcharts";

// Vcalendar
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';

// Maska (input mask)
import Maska from 'maska'

// Tabler UI
import '@tabler/core/dist/css/tabler.css';
import '@tabler/core/dist/js/tabler';
import './assets/styles.css';

// pages
import AuthPage from './pages/AuthPage.vue';
// import LoginPage from './pages/LoginPage.vue';
// import LoginAsCustomerPage from './pages/LoginAsCustomerPage.vue';
import FlowsPage from './pages/FlowsPage.vue';
import FlowEditPage from './pages/FlowEditPage.vue';
import FlowDeletePage from './pages/FlowDeletePage.vue';
import MonitoringPage from './pages/MonitoringPage.vue';
import MonitoringActiveFlowsPage from './pages/MonitoringActiveFlowsPage.vue';
import MonitoringCompletedFlowsPage from './pages/MonitoringCompletedFlowsPage.vue';
import VariablesPage from './pages/VariablesPage.vue';
import CustomNodesPage from './pages/CustomNodesPage.vue';
import CustomNodeEditPage from './pages/CustomNodeEditPage.vue';
import CustomNodeDeletePage from './pages/CustomNodeDeletePage.vue';
import ServiceAccountsPage from './pages/ServiceAccountsPage.vue';
import Oauth2CallbackPage from './pages/Oauth2CallbackPage.vue';
import DbTablesPage from './pages/DbTablesPage.vue';
import DbTableEditPage from './pages/DbTableEditPage.vue';
import DbTableDeletePage from './pages/DbTableDeletePage.vue';
import DbEntitiesPage from './pages/DbEntitiesPage.vue';
import DbEntityEditPage from './pages/DbEntityEditPage.vue';
import QueuesPage from './pages/QueuesPage.vue';
import FormsPage from './pages/FormsPage.vue';
import FormEditPage from './pages/FormEditPage.vue';
import FormDeletePage from './pages/FormDeletePage.vue';
import QueueEditPage from './pages/QueueEditPage.vue';
import QueueDeletePage from './pages/QueueDeletePage.vue';
import QueueViewPage from './pages/QueueViewPage.vue';
import DashboardsPage from './pages/DashboardsPage.vue';
import DashboardEditPage from './pages/DashboardEditPage.vue';
import DashboardViewPage from './pages/DashboardViewPage.vue';
import DashboardDeletePage from './pages/DashboardDeletePage.vue';

// import AccountStatusPage from './pages/AccountStatusPage.vue';
// import Oauth2TrelloCallbackPage from './pages/Oauth2TrelloCallbackPage.vue';

import { store } from './store';

const routes = [
    { path: '/auth', component: AuthPage },
    // { path: '/login-as-customer', component: LoginAsCustomerPage },
    { path: '/', redirect: '/flows', beforeEnter: checkLogin },
    { path: '/flows', component: FlowsPage, beforeEnter: checkLogin },
    { path: '/flows/new', component: FlowEditPage, beforeEnter: checkLogin },
    { path: '/flows/:id', component: FlowEditPage, beforeEnter: checkLogin },
    { path: '/flows/delete/:id', component: FlowDeletePage, beforeEnter: checkLogin },
    { path: '/custom-nodes', component: CustomNodesPage, beforeEnter: checkLogin },
    { path: '/custom-nodes/new', component: CustomNodeEditPage, beforeEnter: checkLogin },
    { path: '/custom-nodes/:id', component: CustomNodeEditPage, beforeEnter: checkLogin },
    { path: '/custom-nodes/delete/:id', component: CustomNodeDeletePage, beforeEnter: checkLogin },
    { path: '/monitoring', component: MonitoringPage, beforeEnter: checkLogin },
    { path: '/monitoring/active', component: MonitoringActiveFlowsPage, beforeEnter: checkLogin },
    { path: '/monitoring/completed', component: MonitoringCompletedFlowsPage, beforeEnter: checkLogin },
    { path: '/variables', component: VariablesPage, beforeEnter: checkLogin },
    { path: '/service-accounts', component: ServiceAccountsPage, beforeEnter: checkLogin },
    { path: '/database/tables', component: DbTablesPage, beforeEnter: checkLogin },
    { path: '/database/tables/new', component: DbTableEditPage, beforeEnter: checkLogin },
    { path: '/database/tables/edit/:table_id', component: DbTableEditPage, beforeEnter: checkLogin },
    { path: '/database/tables/delete/:table_id', component: DbTableDeletePage, beforeEnter: checkLogin },
    { path: '/database/entities/:table_id', component: DbEntitiesPage, beforeEnter: checkLogin },
    { path: '/database/entities/new/:table_id', component: DbEntityEditPage, beforeEnter: checkLogin },
    { path: '/database/entities/edit/:entity_id', component: DbEntityEditPage, beforeEnter: checkLogin },
    { path: '/forms', component: FormsPage, beforeEnter: checkLogin },
    { path: '/forms/new', component: FormEditPage, beforeEnter: checkLogin },
    { path: '/forms/edit/:id', component: FormEditPage, beforeEnter: checkLogin },
    { path: '/forms/delete/:id', component: FormDeletePage, beforeEnter: checkLogin },
    { path: '/queues', component: QueuesPage, beforeEnter: checkLogin },
    { path: '/queues/new', component: QueueEditPage, beforeEnter: checkLogin },
    { path: '/queues/edit/:id', component: QueueEditPage, beforeEnter: checkLogin },
    { path: '/queues/view/:id', component: QueueViewPage, beforeEnter: checkLogin },
    { path: '/queues/delete/:id', component: QueueDeletePage, beforeEnter: checkLogin },
    { path: '/oauth2/callback', component: Oauth2CallbackPage, beforeEnter: checkLogin },
    { path: '/dashboards', component: DashboardsPage, beforeEnter: checkLogin },
    { path: '/dashboards/new', component: DashboardEditPage, beforeEnter: checkLogin },
    { path: '/dashboards/edit/:id', component: DashboardEditPage, beforeEnter: checkLogin },
    { path: '/dashboards/view/:id', component: DashboardViewPage, beforeEnter: checkLogin },
    { path: '/dashboards/delete/:id', component: DashboardDeletePage, beforeEnter: checkLogin },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

function checkLogin(to, from, next){
    if(!localStorage.getItem('token')){
        return window.location.href = process.env.VUE_APP_ACCOUNT_URL
    }

    if (localStorage.getItem('token') && localStorage.getItem('tokenExpiration')) {
        let tokenExpiration = localStorage.getItem('tokenExpiration');
        if (tokenExpiration <= Date.now()) {
            return window.location.href = process.env.VUE_APP_ACCOUNT_URL
        }
    }

    next();
}

// global events
EventBus.on('call-logout', () => {
    localStorage.removeItem('loginAsCustomer');
    localStorage.removeItem("adminUserId");
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('workspace');

    window.location.href = process.env.VUE_APP_ACCOUNT_URL + 'user/logout'
})

EventBus.on('updated-user', (userData) => {
    store.user = userData;
    localStorage.setItem('user', JSON.stringify(userData));
})

EventBus.on('set-workspace', (workspace) => {
    store.workspace = workspace
    localStorage.setItem('workspace', JSON.stringify(workspace));
    EventBus.emit('workspace-selected', workspace)
})

EventBus.on('clear-flow-editor-elements', () => {
    document.body.classList.remove('flow-editor');
    document.querySelectorAll('.leader-line').forEach(el => el.remove());
    document.querySelectorAll('#leader-line-defs').forEach(el => el.remove());
})

// initialize store data
store.user = JSON.parse(localStorage.getItem('user'));
if (localStorage.getItem('workspace') && !store.workspace){
    store.workspace = JSON.parse(localStorage.getItem('workspace'))
    EventBus.emit('workspace-selected', store.workspace)
}

// clear messages on router change
router.beforeEach((to, from, next) => {
    EventBus.emit('clear-flow-editor-elements');
    store.showBackButton = false;
    store.backUrl = null;
    store.sidebarSize = 'full';
    document.body.classList.remove('live-debugger-active');
    next();
})

// apply body class when ajax request is running
EventBus.on('ajax-request-start', () => {
    document.body.classList.add('ajax-request');
})

EventBus.on('ajax-request-end', () => {
    setTimeout(() => {
        document.body.classList.remove('ajax-request');
    }, 300);
})

// register token expiration verification at every 1 minutes
setInterval(() => {
    let tokenExpiration = localStorage.getItem('tokenExpiration');
    if (tokenExpiration) {
        // if token is about to expire (15 min before), send a refresh token request
        if (tokenExpiration <= Date.now() + 60 * 1000 * 15) {
            console.log('refreshing expirated token...')

            AccountApi.refreshToken().then(async response => {
                let data = JSON.parse(atob(response.data.token.split('.')[1]));
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('tokenExpiration', data.exp * 1000);

                var userResponse = await Api.users.me();
                EventBus.emit('updated-user', userResponse.data);
            })
        }
    }
}, 1000 * 60); // every 1 minute

var app = createApp(App);

app.use(VCalendar, {});
app.use(Maska)
app.use(router);
app.use(VueApexCharts);
app.mount('#app');