<template>
    <div>
        <ul class="pagination">
            <li class="page-item">
                <button class="btn" v-on:click.prevent="setPage(currentPage - 1)" v-bind:disabled="currentPage <= 1">
                    <i class="ti ti-chevron-left"></i>
                    Anterior
                </button>
            </li>

            <li class="page-item m-1" v-for="page in pages" v-bind:key="page" v-bind:class="{ 'active': page === currentPage }">
                <a class="page-item page-link" href="#" v-on:click.prevent="setPage(page)">
                    {{ page }}
                </a>
            </li>

            <li class="page-item px-2" v-if="totalPages > maxVisiblePageItems">
                <input type="text" v-model="currentPageInput" @change="setPage(currentPageInput)" class="form-control input-page">
            </li>
            
            <li class="page-item ml-1">
                <button class="btn" href="#" v-on:click.prevent="setPage(currentPage + 1)" v-bind:disabled="currentPage >= totalPages">
                    Próxima
                    <i class="ti ti-chevron-right"></i>
                </button>
            </li>

            <li class="page-item m-2">
                <strong> <span v-if="total == 10000">+</span>{{total}} item(s)</strong> - {{currentPage}} de {{totalPages}} página(s)
            </li>
        </ul>
    </div>
</template>

<style scoped>
.input-page {
    width: 60px;
}
</style>

<script>
export default {
    name: "Pagination",
    props: {
        showPerPage: {
            type: Number,
            default: 20
        },
        currentPage: {
            type: Number,
            default: 1
        },
        total: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            totalPages: 0,
            maxVisiblePageItems: 5,
            currentPageInput: 1,
            pages: []
        };
    },
    mounted() {
        this.currentPageInput = this.currentPage;
        this.updateTotalPages();
        this.$watch('currentPage', (newVal) => {
            this.currentPageInput = newVal;
        });
        this.$watch('total', () => {
            this.updateTotalPages();
        });
    },
    methods: {
        setPage(page) {
            page = parseInt(page);
            this.$emit('pageChange', page);
        },
        updateTotalPages() {
            this.pages = [];
            this.totalPages = Math.ceil(this.total / this.showPerPage);
            if (this.totalPages <= this.maxVisiblePageItems) {
                for (let i = 1; i <= this.totalPages; i++) {
                    this.pages.push(i);
                }
            }
        }
    }
}
</script>