<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h3 class="card-title">Remover fila</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p class="mb-3">
                                    Tem certeza que deseja remover a fila interna <strong>{{queue.name}}</strong>?<br>
                                    <span class="text-red">Todos os jobs pendentes da fila serão removidos e esta ação não poderá ser desfeita!</span>
                                </p>
                                <div class="form-group">
                                    <label class="form-label">Para confirmar a remoção, digite abaixo o texto <span class="bg-red-lt px-1">{{queue.name}}</span>:</label>
                                    <input type="text" class="form-control" v-model="nameConfirmation">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col">
                                <a href="/queues/" class="btn">
                                    <i class="ti ti-arrow-left"></i>
                                    Cancelar e voltar
                                </a>
                            </div>
                            <div class="col-auto">
                                <a v-on:click="removeQueue" class="btn btn-danger">
                                    <i class="ti ti-trash"></i>
                                    Remover fila
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../services/api";
import EventBus from "../services/event-bus";

export default {
    name: "QueueDeletePage",
    data() {
        return {
            nameConfirmation: "",
            queue: {}
        };
    },
    async mounted() {
        var id = this.$route.params.id;
        var response = await Api.queues.get(id);
        this.queue = response.data;
    },
    methods: {
        async removeQueue() {
            var id = this.$route.params.id;

            try {
                if (this.validate()){
                    await Api.queues.delete(id);

                    this.$router.push("/queues");

                    EventBus.emit('message', {
                        type: 'success',
                        message: 'A fila foi removida com sucesso!'
                    })
                }
            } catch (error) {
                EventBus.emit('message', {
                    type: 'error',
                    message: 'Erro ao remover a fila:' + error
                })
            }
        },
        validate(){
            if (this.queue.name !== this.nameConfirmation){
                EventBus.emit('message', {
                    type: 'warning',
                    message: 'O nome da fila não confere com o nome digitado!'
                })

                return false;
            }
            return true;
        }
    }
}
</script>