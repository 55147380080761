<template>
    <div class="page-wrapper">
        <div class="row mb-2">
            <div class="col">
                <WorkspaceEngineStatus />
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="row m-3 mb-1">
                        <div class="col">
                            <h3 class="card-title">Execução em tempo real</h3>
                        </div>
                        <div class="col-auto">
                            <span class="badge bg-green-lt" v-if="statusList.length">{{ statusList.length }} flow(s) em
                                execução</span>
                            <span class="badge bg-blue-lt" v-else>0 flow(s) em execução</span>
                        </div>
                    </div>

                    <div class="card" v-show="!statusList.length">
                        <div class="card-body">
                            <p class="text-muted">
                                <span class="execution-spinner"></span>
                                Aguardando execuções...
                            </p>
                        </div>
                    </div>

                    <div v-show="statusList.length">
                        <table class="table table-vcenter card-table">
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Flow</th>
                                    <th>Iniciador</th>
                                    <th>ID da execução</th>
                                    <th>Inspecionar</th>
                                    <th>Iniciado em</th>
                                    <th>Tempo de execução</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="status in statusList" :key="status.execution_id">
                                    <td>
                                        <div class="badge bg-green text-green-fg">Executando</div>
                                    </td>
                                    <td>
                                        <a :href="'/flows/' + status.flow_id" target="_blank">{{ status.flow_name }}</a>
                                    </td>
                                    <td>
                                        {{ getNodeName(status.trigger_type) }} <small class="text-muted">UID: {{
                                            status.trigger_node_uid }}</small>
                                    </td>
                                    <td>
                                        {{ status.execution_id }} <br>
                                    </td>
                                    <td>
                                        <button class="btn btn-sm btn-outline-primary" @click="goToLogs(status)">
                                            <i class="ti ti-search"></i>
                                            Ver logs
                                        </button>
                                    </td>
                                    <td>
                                        <small class="text-muted">{{ formatDatetime(status.start_time) }}</small>
                                    </td>
                                    <td>
                                        <span class="badge bg-blue-lt">{{ getElapsedTime(status.start_time) }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
#executions-chart {
    height: 160px;
}

.execution-spinner {
    width: 16px;
    height: 16px;
    border: 3px solid #ddd;
    border-bottom-color: #333;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>

<script>
import moment from 'moment-timezone';
import { store } from '../store';
import EventBus from '../services/event-bus'
import EngineApi from "../services/engine-api";
import WorkspaceEngineStatus from '../components/WorkspaceEngineStatus';

export default {
    name: 'MonitoringActiveFlowsPage',
    components: {
        WorkspaceEngineStatus
    },
    data() {
        return {
            socket: null,
            statusList: [],
            interval: null,
            nodesLibrary: []
        }
    },
    beforeUnmount() {
        console.log('unmounted')
        clearInterval(this.interval)
        this.socket.disconnect()
    },
    async mounted() {
        moment.locale('pt-br')

        EventBus.emit('set-header-title', 'Flows ativos em tempo real')

        var nodesLibraryResponse = await EngineApi.getNodesLibrary();
        this.nodesLibrary = nodesLibraryResponse.data;

        this.initRealtimeData()
    },
    methods: {
        async initRealtimeData() {
            var url = store.workspace.engine_url + 'live-monitoring'

            this.socket = io(url)

            this.socket.on('connect', () => {
                console.log('%c🚀 Floui Live Monitoring connected!', 'color: #206bc4')
            })

            // on demand update
            this.socket.on('status-list-updated', (data) => {
                console.log('status-list-updated')
                this.statusList = data
            })

            // interval update
            this.socket.on('status-list', (data) => {
                console.log('status-list')
                this.statusList = data
            })

            this.socket.on('disconnect', () => {
                console.log('%cFloui Live Monitoring disconnected!', 'color: #ccc')
            })

            this.interval = setInterval(() => {
                this.socket.emit('get-status-list')
            }, 1000)
        },
        getElapsedTime(start_time) {
            var start = moment(start_time);
            var end = moment();
            var duration = moment.duration(end.diff(start));

            return moment.utc(duration.asMilliseconds()).format('HH:mm:ss')
        },
        getFromNow(string) {
            return moment(string).fromNow()
        },
        formatDatetime(string) {
            return moment(string).format('DD/MM/YYYY HH:mm:ss')
        },
        getNodeByType(type) {
            return this.nodesLibrary.nodes.find(node => node.name == type)
        },
        getNodeName(type) {
            var node = this.getNodeByType(type)
            return node ? node.label : type
        },
        goToLogs(status) {
            this.$router.push({
                path: '/monitoring',
                query: {
                    filters: JSON.stringify({
                        date_alias: '1000d',
                        execution_id: status.execution_id
                    })
                }
            })
        }
    }
}
</script>