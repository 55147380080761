import axios from 'axios';
import EventBus from './event-bus';

const API_URL = process.env.VUE_APP_API_URL;

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = API_URL;
axiosInstance.interceptors.request.use((request) => {
    if (localStorage.getItem('token')) {
        request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
    }
    return request
})

// redirect to login page when receiving 403 response
axiosInstance.interceptors.response.use((response) => {
    return response
}, (error) => {
    if (error.response.status === 403) {
        EventBus.emit('call-logout')
    }

    EventBus.emit('ajax-request-end');
    return Promise.reject(error)
})


// call event bus when ajax start
axiosInstance.interceptors.request.use((request) => {
    if (!axiosInstance._disable_loaders){
        EventBus.emit('ajax-request-start');
    }
    return request
})

// call event bus when ajax end
axiosInstance.interceptors.response.use((response) => {
    axiosInstance._disable_loaders = false;
    EventBus.emit('ajax-request-end');
    return response
})

export default {
    refreshToken () {
        return axiosInstance.put('v1/users/refresh');
    },

    users: {
        me: () => {
            return axiosInstance.get('v1/users/me');
        }
    },

    /**
     * Workspaces operations
     */
    workspaces: {
        /**
         * Get all workspaces
         * @returns {Promise<Object>}
         */
        getCurrentWorkspace: () => {
            return axiosInstance.get('v1/workspaces/current');
        }
    },

    /**
     * Flows operations
     */
    flows: {
        /**
         * Get all flows by workspace
         * @returns {Promise<Object>}
         */
        list: (currentPage = 1, showPerPage = 20, filter = {}) => {
            var params = {
                page: currentPage,
                limit: showPerPage,
                query: filter.query ?? null,
                status: filter.show_status ?? null,
            }
            
            return axiosInstance.get('v1/flows', {params});
        },

        /**
         * Get flow by id
         * @param {String} id
         * @returns {Promise<Object>}
         */
        get: (id) => {
            return axiosInstance.get('v1/flows/' + id);
        },

        /**
         * Create flow
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        create: (data) => {
            return axiosInstance.post('v1/flows', data);
        },

        /**
         * Update flow
         * @param {String} id
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        update: (id, data) => {
            return axiosInstance.put('v1/flows/' + id, data);
        },

        /**
         * Duplicate flow by id
         * @param {String} id
         * @returns {Promise<Object>}
         */
        duplicate: (id) => {
            return axiosInstance.post('v1/flows/duplicate/' + id);
        },

        /**
         * Delete flow
         * @param {String} id
         * @returns {Promise<Object>}
         */
        delete: (id) => {
            return axiosInstance.delete('v1/flows/' + id);
        }
    },


    /**
     * Database operations
     */
    database: {
        tables: {
            /**
             * Get tables
             * @returns {Promise<Object>}
             */
            list: (currentPage = 1, showPerPage = 20, filter = {}) => {
                var params = {
                    page: currentPage,
                    limit: showPerPage,
                    query: filter.query ?? null
                }
                
                return axiosInstance.get('v1/db/tables', {params});
            },

            /**
             * Get all tables
             * @returns {Promise<Object>}
             */
            listAll: () => {
                var params = {
                    page: 1,
                    limit: 1000
                }
                return axiosInstance.get('v1/db/tables', {params});
            },

            /**
             * Get table by id
             * @param {String} id
             * @returns {Promise<Object>}
             */
            get: (id) => {
                return axiosInstance.get('v1/db/tables/' + id);
            },

            /**
             * Create table
             * @param {Object} data
             * @returns {Promise<Object>}
             */
            create: (data) => {
                return axiosInstance.post('v1/db/tables', data);
            },

            /**
             * Update table
             * @param {String} id
             * @param {Object} data
             * @returns {Promise<Object>}
             */
            update: (id, data) => {
                return axiosInstance.put('v1/db/tables/' + id, data);
            },

            /**
             * Duplicate table by id
             * 
             * @param {String} id 
             * @returns 
             */
            duplicate: (id, name) => {
                return axiosInstance.post('v1/db/tables/' + id + '/duplicate', {name: name});
            },

            /**
             * Delete table
             * @param {String} id
             * @returns {Promise<Object>}
             */
            delete: (id) => {
                return axiosInstance.delete('v1/db/tables/' + id);
            }
        },

        entities: {
            /**
             * Get all entities
             * @returns {Promise<Object>}
             */
            list: (currentPage = 1, showPerPage = 20, filter = {}) => {
                var params = {
                    page: currentPage,
                    limit: showPerPage
                }

                Object.assign(params, filter);
                
                return axiosInstance.get('v1/db/entities', {params});
            },

            /**
             * Export entities to CSV file
             * 
             * @param {String} table_id 
             * @param {Object} filter 
             * @param {Array<String>} columns 
             * @returns {Object}
             */
            exportCsv: (table_id, filter = {}, columns = []) => {
                var params = {
                    columns: columns
                }

                Object.assign(params, filter);

                return axiosInstance.get(`v1/db/entities/export/${table_id}/csv`, {params});
            },

            /**
             * Get entity by id
             * @param {String} id
             * @returns {Promise<Object>}
             */
            get: (id) => {
                return axiosInstance.get('v1/db/entities/' + id);
            },

            /**
             * Create entity
             * @param {Object} data
             * @returns {Promise<Object>}
             */
            create: (data) => {
                return axiosInstance.post('v1/db/entities', data);
            },

            /**
             * Update entity
             * @param {String} id
             * @param {Object} data
             * @returns {Promise<Object>}
             */
            update: (id, data) => {
                return axiosInstance.put('v1/db/entities/' + id, data);
            },

            /**
             * Delete entity
             * @param {String} id
             * @returns {Promise<Object>}
             */
            delete: (id) => {
                return axiosInstance.delete('v1/db/entities/' + id);
            },

            /**
             * Mass delete entities
             * @param {Array<String>} ids
             * @returns {Promise<Object>}
             */
            massDelete: (ids) => {
                return axiosInstance.delete('v1/db/entities/mass', { data: ids });
            }
        }
    },

    monitoring: {
        /**
         * Get all flows logs (logs generated by user nodes)
         * @returns {Promise<Object>}
         */
         getAllLogs: (page = 1, size = 20, filters = {}) => {
            var params = {
                page: page,
                size: size,
                filters: filters
            }
            return axiosInstance.get('v1/monitoring/logs', { params });
        },

        /**
         * Get completed executions
         * @returns {Promise<Object>}
         */
        getExecutionsCompleted: (page = 1, size = 20) => {
            var params = {
                page: page,
                size: size
            }
            return axiosInstance.get('v1/monitoring/executions/completed', { params });
        },

        /**
         * Get completed executions for last hour (timeseries for chart)
         * @returns {Promise<Object>}
         */
        getExecutionsCompletedTimeseries: () => {
            return axiosInstance.get('v1/monitoring/executions/completed/timeseries');
        }
    },

    /**
     * Variables operations
     */
    variables: {
        /**
         * Get all variables by workspace
         * @returns {Promise<Object>}
         */
        list: () => {
            return axiosInstance.get('v1/variables?limit=1000');
        },

        /**
         * Get variable by id
         * @param {String} id
         * @returns {Promise<Object>}
         */
        get: (id) => {
            return axiosInstance.get('v1/variables/' + id);
        },

        /**
         * Create variable
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        create: (data) => {
            return axiosInstance.post('v1/variables', data);
        },

        /**
         * Update variable
         * @param {String} id
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        update: (id, data) => {
            return axiosInstance.put('v1/variables/' + id, data);
        },

        /**
         * Delete variable
         * @param {String} id
         * @returns {Promise<Object>}
         */
        delete: (id) => {
            return axiosInstance.delete('v1/variables/' + id);
        }
    },

    /**
     * Custom nodes operations
     */
    customNodes: {
        /**
         * Get custom nodes
         * @returns {Promise<Object>}
         */
        list: (currentPage = 1, showPerPage = 20, filter = {}) => {
            var params = {
                page: currentPage,
                limit: showPerPage,
                query: filter.query ?? null
            }
            
            return axiosInstance.get('v1/custom-nodes', {params});
        },

        /**
         * Get custom-node by id
         * @param {String} id
         * @returns {Promise<Object>}
         */
        get: (id) => {
            return axiosInstance.get('v1/custom-nodes/' + id);
        },

        /**
         * Create custom-node
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        create: (data) => {
            return axiosInstance.post('v1/custom-nodes', data);
        },

        /**
         * Update custom-node
         * @param {String} id
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        update: (id, data) => {
            return axiosInstance.put('v1/custom-nodes/' + id, data);
        },

        /**
         * Duplicate custom node by id
         * @param {String} id
         * @returns {Promise<Object>}
         */
        duplicate: (id) => {
            return axiosInstance.post('v1/custom-nodes/duplicate/' + id);
        },

        /**
         * Delete custom-node
         * @param {String} id
         * @returns {Promise<Object>}
         */
        delete: (id) => {
            return axiosInstance.delete('v1/custom-nodes/' + id);
        }
    },

    /**
     * Dashboards operations
     */
    dashboards: {
        /**
         * Get custom nodes
         * @returns {Promise<Object>}
         */
        list: (currentPage = 1, showPerPage = 20, filter = {}) => {
            var params = {
                page: currentPage,
                limit: showPerPage,
                query: filter.query ?? null
            }
            
            return axiosInstance.get('v1/dashboards', {params});
        },

        /**
         * Get custom-node by id
         * @param {String} id
         * @returns {Promise<Object>}
         */
        get: (id) => {
            return axiosInstance.get('v1/dashboards/' + id);
        },

        /**
         * Create custom-node
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        create: (data) => {
            return axiosInstance.post('v1/dashboards', data);
        },

        /**
         * Update custom-node
         * @param {String} id
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        update: (id, data) => {
            return axiosInstance.put('v1/dashboards/' + id, data);
        },

        /**
         * Duplicate custom node by id
         * @param {String} id
         * @returns {Promise<Object>}
         */
        duplicate: (id) => {
            return axiosInstance.post('v1/dashboards/duplicate/' + id);
        },

        /**
         * Delete custom-node
         * @param {String} id
         * @returns {Promise<Object>}
         */
        delete: (id) => {
            return axiosInstance.delete('v1/dashboards/' + id);
        },

        /**
         * Query dashboard data
         * @param {String} id
         * @returns {Promise<Object>}
         */
        query: (id = null, data = {}, viewOptions = {}) => {
            return axiosInstance.post('v1/dashboards/query', {id, data, viewOptions});
        },
    },

    /**
     * Forms operations
     */
    forms: {
        /**
         * Get forms
         * @returns {Promise<Object>}
         */
        list: (currentPage = 1, showPerPage = 20, filter = {}) => {
            var params = {
                page: currentPage,
                limit: showPerPage,
                query: filter.query ?? null
            }
            
            return axiosInstance.get('v1/forms', {params});
        },

        /**
         * Get all forms
         * @returns {Promise<Object>}
         */
        listAll: () => {
            var params = {
                page: 1,
                limit: 1000
            }
            
            return axiosInstance.get('v1/forms', {params});
        },

        /**
         * Get form by id
         * @param {String} id
         * @returns {Promise<Object>}
         */
        get: (id) => {
            return axiosInstance.get('v1/forms/' + id);
        },

        /**
         * Create form
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        create: (data) => {
            return axiosInstance.post('v1/forms', data);
        },

        /**
         * Update form
         * @param {String} id
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        update: (id, data) => {

            return axiosInstance.put('v1/forms/' + id, data);
        },

        /**
         * Delete form
         * @param {String} id
         * @returns {Promise<Object>}
         */
        delete: (id) => {
            return axiosInstance.delete('v1/forms/' + id);
        }
    },

    /**
     * Queues operations
     */
    queues: {
        /**
         * Get all queues
         * @returns {Promise<Object>}
         */
        list: (currentPage = 1, showPerPage = 20, filter = {}) => {
            var params = {
                page: currentPage,
                limit: showPerPage,
                query: filter.query ?? null
            }
            
            return axiosInstance.get('v1/queues', {params});
        },

         /**
         * Get all queues
         * @returns {Promise<Object>}
         */
         listAll: () => {
            var params = {
                page: 1,
                limit: 1000
            }
            
            return axiosInstance.get('v1/queues', {params});
        },

        /**
         * Get queue by id
         * @param {String} id
         * @returns {Promise<Object>}
         */
        get: (id) => {
            return axiosInstance.get('v1/queues/' + id);
        },

        /**
         * Get all queues
         * @returns {Promise<Object>}
         */
        listJobs: (queueId, currentPage = 1, showPerPage = 20, statusList) => {
            var params = {
                page: currentPage,
                limit: showPerPage,
                statusList: statusList
            }
            
            return axiosInstance.get(`v1/queues/jobs/${queueId}`, {params});
        },

        /**
         * Create queue
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        create: (data) => {
            return axiosInstance.post('v1/queues', data);
        },

        /**
         * Update queue
         * @param {String} id
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        update: (id, data) => {

            return axiosInstance.put('v1/queues/' + id, data);
        },

        /**
         * Delete queue
         * @param {String} id
         * @returns {Promise<Object>}
         */
        delete: (id) => {
            return axiosInstance.delete('v1/queues/' + id);
        },

        /**
         * Pause queue by id
         * @param {String} id
         * @returns {Promise<Object>}
         */
        pause: (id) => {
            return axiosInstance.post('v1/queues/control/pause/' + id);
        },

        /**
         * Resume queue by id
         * @param {String} id
         * @returns {Promise<Object>}
         */
        resume: (id) => {
            return axiosInstance.post('v1/queues/control/resume/' + id);
        },

        /**
         * Clean queue by id
         * @param {String} id
         * @returns {Promise<Object>}
         */
        clean: (id) => {
            return axiosInstance.post('v1/queues/control/clean/' + id);
        },

        /**
         * Create queue job
         * @param {String} id
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        addJob: (id, data) => {
            return axiosInstance.post('v1/queues/jobs/new/' + id, data);
        },

        /**
         * Remove queue job
         * @param {String} queueId
         * @param {Number} jobId
         * @returns {Promise<Object>}
         */
        removeJob: (queueId, id) => {
            return axiosInstance.delete('v1/queues/jobs/remove/' + queueId + '/' + id);
        },
    },

    /**
     * Service Accounts operations
     */
     serviceAccounts: {
        /**
         * Get all variables by workspace
         * @returns {Promise<Object>}
         */
        list: () => {
            return axiosInstance.get('v1/service-accounts?limit=1000');
        },

        /**
         * Get all service-accounts
         * @returns {Promise<Object>}
         */
        listOptions: () => {
            axiosInstance._disable_loaders = true;
            return axiosInstance.get('v1/service-accounts?limit=1000');
        },

        /**
         * Get service-account by id
         * @param {String} id
         * @returns {Promise<Object>}
         */
        get: (id) => {
            return axiosInstance.get('v1/service-accounts/' + id);
        },

        /**
         * Create service-account
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        create: (data) => {
            return axiosInstance.post('v1/service-accounts', data);
        },

        /**
         * Update service-account
         * @param {String} id
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        update: (id, data) => {
            return axiosInstance.put('v1/service-accounts/' + id, data);
        },

        /**
         * Update service-account
         * @param {String} id
         * @param {Object} data
         * @returns {Promise<Object>}
         */
        updateOauth2: (id, data) => {
            return axiosInstance.put('v1/service-accounts/' + id + '/oauth2', data);
        },

        /**
         * Delete service-account
         * @param {String} id
         * @returns {Promise<Object>}
         */
        delete: (id) => {
            return axiosInstance.delete('v1/service-accounts/' + id);
        },


        /**
         * Trello specific operations
         */
        trello: {
            /**
             * Get boards from account
             * @param {String} service_account_id 
             * @returns 
             */
            getBoards(service_account_id) {
                axiosInstance._disable_loaders = true;
                return axiosInstance.get('v1/service-accounts/trello/boards/' + service_account_id);
            },

            /**
             * Get lists from board (columns of board)
             * @param {String} service_account_id 
             * @param {String} board_id 
             * @returns 
             */
            getBoardLists(service_account_id, board_id) {
                axiosInstance._disable_loaders = true;
                return axiosInstance.get('v1/service-accounts/trello/boards/' + service_account_id + '/lists/' + board_id);
            }
        },

        /**
         * Slack specific operations
         */
        slack: {
            /**
             * Get channels from account
             * @param {String} service_account_id 
             * @returns 
             */
            getChannels(service_account_id) {
                axiosInstance._disable_loaders = true;
                return axiosInstance.get('v1/service-accounts/slack/channels/' + service_account_id);
            }
        }
    }
}