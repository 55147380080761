<template>
    <div>
        <div>
            <div class="page-wrapper">
                <div class="page-body">
                    <div class="row g-2">
                        <div class="col">
                            <div class="card">
                                <div class="card-header">
                                    <div class="col">
                                        <h3 class="card-title">
                                            <i class="ti ti-chart-bar"></i>
                                            Dashboards
                                        </h3>
                                    </div>
                                    <div class="col-auto">
                                        <a href="/dashboards/new" class="btn btn-primary">
                                            <i class="ti ti-plus"></i>
                                            Criar dashboard
                                        </a>
                                    </div>
                                </div>

                                <div class="table" v-if="dashboardsList.length">
                                    <table class="table table-vcenter card-table">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th width="20%">Atualização</th>
                                                <th width="20%">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="dashboard in dashboardsList" v-bind:key="dashboard._id">
                                                <td>
                                                    {{ dashboard.name }}
                                                </td>
                                                <td>
                                                    {{ formatDate(dashboard.updated_at) }}
                                                </td>
                                                <td>
                                                    <a v-bind:href="'/dashboards/view/' + dashboard._id" class="btn btn-sm">
                                                        <i class="ti ti-eye"></i>
                                                        Visualizar
                                                    </a>
                                                    <span>&nbsp;</span>
                                                    <span class="dropdown">
                                                        <button class="btn btn-sm dropdown-toggle"
                                                            data-bs-toggle="dropdown">
                                                            Opções
                                                        </button>
                                                        <div class="dropdown-menu">
                                                            <a v-bind:href="'/dashboards/edit/' + dashboard._id" class="dropdown-item">
                                                                <i class="ti ti-pencil"></i>
                                                                Editar
                                                            </a>
                                                            <a href="#" @click="(e) => { duplicateDashboard(e, dashboard) }"
                                                                class="dropdown-item">
                                                                <i class="ti ti-copy"></i>
                                                                Duplicar
                                                            </a>
                                                            <a href="#" @click="(e) => { exportDashboard(e, dashboard) }"
                                                                class="dropdown-item">
                                                                <i class="ti ti-download"></i>
                                                                Exportar
                                                            </a>
                                                            <a v-bind:href="'/dashboards/delete/' + dashboard._id"
                                                                class="dropdown-item bg-danger-lt">
                                                                <i class="ti ti-trash"></i>
                                                                Remover
                                                            </a>
                                                        </div>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="card-body" v-if="!dashboardsList.length && !filter.query">
                                    <div class="empty">
                                        <p class="empty-title">
                                            Nenhum dashboard criado ainda.
                                        </p>
                                        <p class="empty-subtitle text-muted">
                                            Clique no botão abaixo para criar seu primeiro dashboard personalizado!
                                        </p>
                                        <div class="empty-action">
                                            <a href="/dashboards/new" class="btn btn-primary">
                                                <i class="ti ti-plus"></i>
                                                Criar dashboard
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-body" v-if="!dashboardsList.length && filter.query">
                                    <div class="empty bg-muted-lt">
                                        <p class="empty-title">
                                            Nenhum dashboard encontrado para esta busca.
                                        </p>
                                    </div>
                                </div>

                                <div class="card-footer">
                                    <Pagination :key="paginationKey" :showPerPage="showPerPage" :currentPage="currentPage"
                                        :total="total" v-if="total" @pageChange="updatePage" v-show="total > showPerPage" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "../services/event-bus";
import Api from "../services/api";
import { store } from "../store";
import Pagination from "../components/Pagination";
import moment from "moment";

export default {
    name: "DashboardsPage",
    components: {
        Pagination
    },
    data() {
        return {
            paginationKey: null,
            showPerPage: 10,
            currentPage: 1,
            total: 0,
            dashboardsList: [],
            filter: {
                query: ""
            }
        }
    },
    async mounted() {
        store.showSidebar = true;
        store.showHeader = true;
    },
    async mounted() {
        this.currentPage = parseInt(this.$route.query.page) || 1;
        this.filter.query = this.$route.query.query || "";
        this.loadDashboards();
    },
    methods: {
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss");
        },
        async loadDashboards(){
            this.paginationKey = Math.random();

            var response = await Api.dashboards.list(this.currentPage, this.showPerPage, this.filter);

            this.dashboardsList = response.data.items;
            this.total = response.data.total;
        },
        updateFilter() {
            this.$router.replace({
                query: {
                    page: 1,
                    query: this.filter.query
                }
            });
            
            this.loadDashboards();
        },

        async duplicateDashboard(e, dashboard) {
            e.preventDefault();

            if (confirm("Deseja realmente duplicar este dashboard?")) {
                await Api.dashboards.duplicate(dashboard._id)

                EventBus.emit('message', {
                    type: 'success',
                    message: 'Dashboard duplicado com sucesso!'
                })
                
                this.$router.go()
            }
        },

        async exportDashboard(e, dashboard) {
            e.preventDefault();

            try {
                const response = await Api.dashboards.get(dashboard._id)
                var dashboard = JSON.parse(JSON.stringify(response.data))

                delete dashboard._id;
                
                const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(dashboard));
                const downloadAnchorNode = document.createElement('a');
                downloadAnchorNode.setAttribute("href", dataStr);
                downloadAnchorNode.setAttribute("download", this.slugify(dashboard.name) + ".dashboard.json");
                document.body.appendChild(downloadAnchorNode); // required for firefox
                downloadAnchorNode.click();
                downloadAnchorNode.remove();

            } catch (error) {
                var message = error.message

                if (error.response.data && error.response.data.message) {
                    message = error.response.data.message
                }

                EventBus.emit('message', {
                    type: "danger",
                    message: message
                })
            }
        },

        slugify(str) {
            return str
                .toLowerCase()
                .replace(/ /g,'-')
                .replace(/[^\w-]+/g,'');
        },
        
        updatePage(page) {
            this.currentPage = page;
            this.paginationKey = Math.random();

            var queryParams = {
                page: page
            }

            if (this.filter.query) queryParams.query = this.filter.query;

            this.$router.replace({
                query: queryParams
            })

            this.loadDashboards();
        }
    }
};
</script>