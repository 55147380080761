<template>
    <div>
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row g-2">
                    <div class="col">
                        <FormsList />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormsList from "../components/FormsList";
import { store } from "../store";

export default {
    name: "FormsPage",
    components: {
        FormsList
    },
    data() {
        return {
            forms: []
        }
    },
    async mounted() {
        store.showSidebar = true;
        store.showHeader = true;
    }
}
</script>