<template>
    <div
        class="modal modal-blur fade"
        id="modal-log-details"
        tabindex="-1"
        style="display: none"
        aria-modal="true"
        role="dialog">
        <div
            class="modal-dialog modal-xl"
            role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Visualizar detalhes do log</h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close">
                    </button>
                </div>
                <div class="modal-body" v-if="logParsed">
                    <div class="row">
                        <div class="col-3">
                            <h5>Log ID</h5>
                            <p>{{logParsed._id}}</p>
                        </div>
                        <div class="col-5">
                            <h5>Data/hora</h5>
                            <p>{{logParsed.timestamp_formatted}}</p>
                        </div>
                        <div class="col-4">
                            <h5>ID da execução</h5>
                            <p>{{logParsed.execution_id}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <h5>Tipo</h5>
                            <p class="badge">{{logParsed.type_name}}</p>
                        </div>
                        <div class="col-5">
                            <h5>Flow</h5>
                            <p>{{logParsed.flow_name}}</p>
                        </div>
                    </div>

                    <div v-if="logParsed.label">
                        <h5>Label</h5>
                        <pre>{{logParsed.label}}</pre>
                    </div>

                    <h5>Mensagem do log</h5>
                    <div class="log-container">
                        <pre v-if="!isValidJson">{{logParsed.message}}</pre>
                        <vue-json-pretty :show-icon="true" v-if="isValidJson" :data="logParsed.message"></vue-json-pretty>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.log-container {
    position: relative;
}
.log-container .pretty-json pre {
    padding: 20px;
}
.log-container .vjs-tree {
    background: #fff;
    padding: 20px 10px;
    border: 1px solid #ddd
}
.log-container .pretty-json {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    opacity: 0.5;
}
.log-container .pretty-json:hover {
    opacity: 1;
}
</style>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    name: 'LogDetails',
    props: {
        log: {
            type: Object,
            required: true
        }
    },
    components: {
        VueJsonPretty
    },
    data(){
        return {
            isValidJson: false,
            isJsonFormatted: false,
            logParsed: null
        }
    },
    mounted(){
        var modal = new bootstrap.Modal(document.getElementById('modal-log-details'), {
            keyboard: false
        })
        modal.show()

        // copy data
        this.logParsed = JSON.parse(JSON.stringify(this.log))

        this.initPrettyJson()
    },
    methods: {
        initPrettyJson(){
            try {
                if (typeof this.logParsed.message === 'string') {
                    var json = JSON.parse(this.logParsed.message)
                    this.logParsed.message = json
                    this.isValidJson = true
                }

                if (typeof this.logParsed.message === 'object') {
                    this.isValidJson = true
                }
            } catch(e) { console.log(e) }
        }
    }
}
</script>